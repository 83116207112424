import config from '../config';
import { EventEmitter } from 'events';
import { io } from 'socket.io-client';
import { uploadFileInChunks } from '../utils/chunkedUpload';

class ChatService extends EventEmitter {
  constructor() {
    super();
    this.connected = false;
    this.userId = null;
    this.userRole = null;
    this.eventRegistered = false;
    this.connectionAttempts = 0;
    this.maxRetries = 3;
    this.isConnecting = false;
    this.messageCache = {};
    this.reconnectTimer = null;
    this.lastPingTime = null;
    this.heartbeatInterval = null;
    
    // 获取或生成客户端ID
    this.clientId = localStorage.getItem('chat_client_id') || this.generateUniqueId();
    localStorage.setItem('chat_client_id', this.clientId);
    // 添加登出事件监听
    window.addEventListener('user_logout', this.handleLogout.bind(this));
    // 添加登录事件监听
    window.addEventListener('user_login', this.handleLogin.bind(this));
  }


  // 添加处理登出的方法
  handleLogout = () => {
    console.log('Chat service handling logout...');
    this.disconnect();
    this.userId = null;
    this.userRole = null;
    this.connected = false;
    this.messageCache = {};
    this.eventRegistered = false;
  }

  // 添加处理登录的方法
  handleLogin = async () => {
    console.log('Chat service handling login...');
    // 重置状态
    this.connected = false;
    this.eventRegistered = false;
    this.messageCache = {};
    
    // 尝试获取新的用户信息并重新连接
    const token = localStorage.getItem('jwt_token');
    if (token) {
      try {
        const response = await fetch(`${config.API_BASE_URL}/check_auth.php`, {
          credentials: 'include'
        });
        const data = await response.json();
        if (data.authenticated && data.user) {
          await this.connect(data.user.id, data.user.role);
        }
      } catch (error) {
        console.error('Failed to reinitialize chat service after login:', error);
      }
    }
  }


  // 添加生成唯一ID的方法
  generateUniqueId() {
    return 'client_' + Date.now() + '_' + Math.random().toString(36).substring(2, 15);
  }

    /**
   * 构建标准会话ID
   * @param {string|number} adminId - 管理员ID
   * @param {string|number} teacherId - 老师ID
   * @returns {string} 标准格式的会话ID
   */
  buildConversationId(adminId, teacherId) {
    return `admin_${adminId}_teacher_${teacherId}`;
  }


  // 保存消息到本地缓存
  saveMessagesToCache(conversationId, messages) {
    if (!conversationId) {
      console.warn('尝试保存缓存时会话ID为空');
      return;
    }
    
    try {
      // 检查是否为AI对话
      const isAiConversation = conversationId.includes('teacher') && conversationId.includes('ai-assistant');
      
      // 确保使用标准会话ID格式
      let standardConversationId = conversationId;
      
      // 只对非AI对话应用常规格式化规则
      if (!isAiConversation && !conversationId.startsWith('admin_')) {
        // 从消息中提取信息构建标准ID
        const firstMsg = messages[0];
        if (firstMsg && firstMsg.sender && firstMsg.receiver) {
          const isFromAdmin = firstMsg.senderRole === 'admin';
          standardConversationId = isFromAdmin 
            ? `admin_${firstMsg.sender}_teacher_${firstMsg.receiver}`
            : `admin_${firstMsg.receiver}_teacher_${firstMsg.sender}`;
        }
      }
      
      console.log(`准备保存消息到缓存，会话ID: ${standardConversationId}，消息数量: ${messages.length}`);
        
        // 合并现有缓存和新消息
        const existingMessages = this.messageCache[standardConversationId] || [];
        console.log(`现有缓存消息数量: ${existingMessages.length}`);
        
        const allMessages = [...existingMessages];
        
        // 添加新消息，避免重复
        let newMessageCount = 0;
        messages.forEach(msg => {
          if (!msg.messageId) {
            console.warn('消息缺少messageId:', msg);
            return;
          }
          
          if (!allMessages.some(m => m.messageId === msg.messageId)) {
            newMessageCount++;
            // 确保保存所有文件相关信息
            if (msg.messageType === 'file' || msg.messageType === 'image') {
              // 只保存必要的信息
              const filteredMessage = {
                ...msg,
                // 基本字段
                fileUrl: msg.fileUrl, // 完整URL，包含域名和token
                fileName: msg.fileName,
                fileSize: msg.fileSize,
                fileMimeType: msg.fileMimeType,
                // 如果有缩略图URL，直接保存到顶层
                ...(msg.thumbnailUrl && { thumbnailUrl: msg.thumbnailUrl }),
                // 精简的元数据
                metadata: {
                  fileId: msg.fileId || msg.metadata?.fileId || null,
                  tokenExpiresAt: msg.metadata?.tokenExpiresAt || null,
                  // 确保缩略图URL也保存在metadata中
                  ...(msg.thumbnailUrl && { thumbnailUrl: msg.thumbnailUrl }),
                  ...(msg.metadata?.thumbnailUrl && { thumbnailUrl: msg.metadata.thumbnailUrl })
                }
              };
              
              allMessages.push(filteredMessage);
            } else {
              allMessages.push(msg);
            }
          }
        });
        
        console.log(`添加了 ${newMessageCount} 条新消息到缓存`);
        
        // 按时间排序
        allMessages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        
        // 更新内存缓存
        this.messageCache[standardConversationId] = allMessages;
        
        // 保存到 localStorage (限制大小，只保留最近的消息)
        const maxCachedMessages = 100;
        const messagesToStore = allMessages.slice(-maxCachedMessages);
        
        try {
          const jsonData = JSON.stringify(messagesToStore);
          const cacheKey = `chat_history_${standardConversationId}`;
          localStorage.setItem(cacheKey, jsonData);
          console.log(`成功保存 ${messagesToStore.length} 条消息到本地存储，会话ID: ${standardConversationId}，缓存键: ${cacheKey}`);
        } catch (storageError) {
          console.error('保存到localStorage失败:', storageError);
          // 如果是配额错误，尝试清理一些旧缓存
          if (storageError.name === 'QuotaExceededError') {
            this.cleanupOldCache();
            // 重试保存
            try {
              localStorage.setItem(`chat_history_${standardConversationId}`, JSON.stringify(messagesToStore));
              console.log('清理缓存后成功保存消息');
            } catch (retryError) {
              console.error('清理缓存后仍无法保存:', retryError);
            }
          }
        }
      } catch (error) {
        console.error('缓存消息失败:', error);
      }
    }

    // 添加清理旧缓存的方法
    cleanupOldCache() {
      console.log('清理旧缓存...');
      try {
        // 查找所有聊天历史缓存键
        const cacheKeys = [];
        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i);
          if (key && key.startsWith('chat_history_')) {
            cacheKeys.push(key);
          }
        }
        
        // 如果缓存键数量超过10个，删除一半最旧的
        if (cacheKeys.length > 10) {
          console.log(`发现 ${cacheKeys.length} 个聊天缓存，准备清理...`);
          // 按最后访问时间排序（这里简化处理，直接删除前一半）
          const keysToRemove = cacheKeys.slice(0, Math.floor(cacheKeys.length / 2));
          keysToRemove.forEach(key => {
            localStorage.removeItem(key);
            console.log(`已删除旧缓存: ${key}`);
          });
        }
      } catch (error) {
        console.error('清理缓存失败:', error);
      }
    }

        // 从本地缓存加载消息
        loadMessagesFromCache(conversationId) {
          if (!conversationId) {
            console.warn('尝试加载缓存时会话ID为空');
            return [];
          }
          
          try {
            // 确保使用标准会话ID格式
            let standardConversationId = conversationId;
            
            // 特殊处理AI对话会话ID
            if (conversationId.includes('ai-assistant')) {
              // 检查是否已包含chat_history_前缀，如果有则去除
              if (conversationId.startsWith('chat_history_')) {
                standardConversationId = conversationId.substring(12); // 移除前缀
              }
              
              // 构建缓存键
              const cacheKey = `chat_history_${standardConversationId}`;
              const cachedData = localStorage.getItem(cacheKey);
              
              if (cachedData) {
                try {
                  const messages = JSON.parse(cachedData);
                  this.messageCache[standardConversationId] = messages;
                  console.log(`从本地存储加载了 ${messages.length} 条消息，会话ID: ${standardConversationId}，缓存键: ${cacheKey}`);
                  return messages;
                } catch (parseError) {
                  console.error('解析缓存数据失败:', parseError);
                  localStorage.removeItem(cacheKey);
                }
              } else {
                console.log(`未找到AI对话缓存数据，会话ID: ${standardConversationId}`);
              }
              
              return [];
            }
            
            // 处理普通对话的逻辑保持不变
            if (!conversationId.startsWith('admin_')) {
              // 尝试从参数中提取信息
              const parts = conversationId.split('_');
              if (parts.length === 2) {
                // 假设格式是 "teacherId_adminId"
                standardConversationId = `admin_${parts[1]}_teacher_${parts[0]}`;
              }
            }
            
            console.log(`尝试加载缓存，会话ID: ${standardConversationId}`);
            
            // 先检查内存缓存
            if (this.messageCache[standardConversationId]) {
              console.log(`从内存缓存加载了 ${this.messageCache[standardConversationId].length} 条消息`);
              return this.messageCache[standardConversationId];
            }
            
            // 从 localStorage 加载
            const cacheKey = `chat_history_${standardConversationId}`;
            const cachedData = localStorage.getItem(cacheKey);
            if (cachedData) {
              try {
                const messages = JSON.parse(cachedData);
                this.messageCache[standardConversationId] = messages;
                console.log(`从本地存储加载了 ${messages.length} 条消息，会话ID: ${standardConversationId}，缓存键: ${cacheKey}`);
                
                return messages;
              } catch (parseError) {
                console.error('解析缓存数据失败:', parseError);
                // 如果解析失败，删除损坏的缓存
                localStorage.removeItem(cacheKey);
              }
            } else {
              console.log(`未找到缓存数据，会话ID: ${standardConversationId}，缓存键: ${cacheKey}`);
            }
          } catch (error) {
            console.error('从缓存加载消息失败:', error);
          }
          
          return [];
        }

      // 添加新消息到缓存
    async addMessageToCache(conversationId, message) {
      if (!conversationId || !message) return;
      
      // 确定这是否是 AI 对话
      const isAiConversation = 
        message.senderRole === 'ai' || 
        message.receiverRole === 'ai' || 
        message.sender === 'ai-assistant' || 
        message.receiver === 'ai-assistant' ||
        (conversationId && conversationId.includes('ai-assistant'));
      
      // 为 AI 对话使用固定格式的会话 ID
      let standardConversationId;
      if (isAiConversation) {
        // 直接使用完整的缓存键
        standardConversationId = `teacher_${this.userId}_ai-assistant`;
      } else {
        // 处理普通对话
        if (!conversationId.startsWith('admin_') && message.sender && message.receiver) {
          const isFromAdmin = message.senderRole === 'admin';
          standardConversationId = isFromAdmin 
            ? `admin_${message.sender}_teacher_${message.receiver}`
            : `admin_${message.receiver}_teacher_${message.sender}`;
          
          // 添加前缀
          standardConversationId = `chat_history_${standardConversationId}`;
        } else {
          // 确保已有前缀
          standardConversationId = conversationId.startsWith('chat_history_') 
            ? conversationId 
            : `chat_history_${conversationId}`;
        }
      }
      
      // 记录日志
      console.log(`添加消息到缓存，使用标准会话ID: ${standardConversationId}，消息ID: ${message.messageId}`);
      
      const messages = this.loadMessagesFromCache(standardConversationId);
      this.saveMessagesToCache(standardConversationId, [...messages, message]);
    }

  async connect(userId, userRole) {
    // 如果正在连接中，直接返回
    if (this.isConnecting) {
      console.log('正在连接中，请等待...');
      return Promise.resolve();
    }

    // 如果已经存在有效连接，直接返回
    if (this.connected && this.userId === userId && this.userRole === userRole && this.socket) {
      console.log('已存在有效连接，无需重新连接');
      return Promise.resolve();
    }

    // 清除任何现有的重连计时器
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer);
      this.reconnectTimer = null;
    }

    this.userId = userId;
    this.userRole = userRole;

    if (!this.userId || !this.userRole) {
      console.error('无效的用户信息:', { userId: this.userId, userRole: this.userRole });
      throw new Error('Invalid user information');
    }

    try {
      this.isConnecting = true;
      const baseUrl = 'https://teacher.theoryabc.com';
      console.log('连接到聊天服务:', baseUrl, '用户信息:', { userId: this.userId, userRole: this.userRole });

      // 先清理旧连接
      if (this.socket) {
        console.log('清理旧连接...');
        this.socket.removeAllListeners();
        this.socket.close();
        this.socket = null;
      }

    // 获取令牌，并主动检查是否过期
      let token = localStorage.getItem('jwt_token');
      let tokenNeedsRefresh = false;

    // 检查令牌是否可能过期（解析JWT而不验证签名）
    if (token) {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const payload = JSON.parse(window.atob(base64));
        
        // 检查令牌是否已过期或即将过期（5分钟内）
        if (payload.exp && payload.exp * 1000 < Date.now() + 5 * 60 * 1000) {
          console.log('JWT令牌已过期或即将过期，尝试刷新');
          tokenNeedsRefresh = true;
        }
      } catch (e) {
        console.error('解析JWT令牌失败:', e);
        tokenNeedsRefresh = true;
      }
    } else {
      tokenNeedsRefresh = true;
    }
    
    // 如果令牌需要刷新，先刷新再连接
    if (tokenNeedsRefresh) {
      try {
        token = await this.refreshToken();
        console.log('令牌已刷新，使用新令牌连接');
      } catch (refreshError) {
        console.error('刷新令牌失败，尝试获取新令牌:', refreshError);
        
        // 尝试从服务器获取新令牌
        try {
          const response = await fetch(`${config.API_BASE_URL}/check_auth.php`, {
            method: 'GET',
            credentials: 'include', // 确保发送cookies
          });
          
          const authData = await response.json();
          if (authData.authenticated && authData.token) {
            token = authData.token;
            localStorage.setItem('jwt_token', token);
            console.log('已获取并保存新的JWT令牌');
          } else {
            console.warn('服务器没有返回有效的JWT令牌');
          }
        } catch (error) {
          console.error('获取JWT令牌失败:', error);
        }
      }
    }
  
      // 创建新连接
      this.socket = io(baseUrl, {
        path: '/chatws/socket.io',
        transports: ['websocket'],
        query: { 
          userId: this.userId,
          userRole: this.userRole,
          token: token, // 添加JWT令牌
          clientId: this.clientId // 添加客户端ID
        },
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 2000,
        reconnectionDelayMax: 10000,
        timeout: 20000,
        forceNew: true
      });

      return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
          if (!this.connected) {
            console.error('连接超时');
            this.isConnecting = false;
            if (this.socket) {
              this.socket.close();
              this.socket = null;
            }
            reject(new Error('连接超时'));
          }
        }, 20000);

        this.socket.on('connect', async () => {
          console.log('聊天服务连接成功');
          this.connected = true;
          this.connectionAttempts = 0;
          this.isConnecting = false;
          this.lastPingTime = Date.now();
          
          // 发送认证信息
          this.socket.emit('authenticate', {
            userId: this.userId,
            userRole: this.userRole,
            clientId: this.clientId,
            token: token
          });
          
          // 设置心跳检测，每45秒发送一次
          this.setupHeartbeat();
          
          // 连接成功后，主动获取离线消息
          if (this.userRole === 'teacher') {
            try {
              // 向服务器请求离线消息
              this.socket.emit('chat:get_offline_messages', {}, (response) => {
                if (response && response.success && response.messages && response.messages.length > 0) {
                  console.log(`收到 ${response.messages.length} 条离线消息`);
                  
                  // 按会话ID分组消息
                  const messagesByConversation = {};
                  response.messages.forEach(msg => {
                    const convId = msg.conversationId || this.buildConversationId(
                      msg.senderRole === 'admin' ? msg.sender : msg.receiver,
                      msg.senderRole === 'teacher' ? msg.sender : msg.receiver
                    );
                    
                    if (!messagesByConversation[convId]) {
                      messagesByConversation[convId] = [];
                    }
                    messagesByConversation[convId].push(msg);
                  });
                  
                  // 保存每个会话的消息到缓存
                  Object.keys(messagesByConversation).forEach(convId => {
                    // 先加载现有缓存
                    const existingMessages = this.loadMessagesFromCache(convId);
                    // 合并并保存
                    this.saveMessagesToCache(convId, [...existingMessages, ...messagesByConversation[convId]]);
                  });
                  
                  // 触发新消息事件
                  this.emit('offline_messages_received', response.messages);
                }
              });
            } catch (error) {
              console.error('获取离线消息失败:', error);
            }
          }
          
          clearTimeout(timeoutId);
          this.setupChatEventListeners();
          resolve();
        });

        this.socket.on('disconnect', (reason) => {
          console.log('聊天服务断开连接，原因:', reason);
          this.connected = false;
          this.isConnecting = false;
          clearInterval(this.heartbeatInterval);
          this.heartbeatInterval = null;
          
          // 根据断开原因采取不同策略
          if (reason === 'io server disconnect') {
            // 服务器主动断开，可能是认证问题，需要手动重连
            console.log('服务器主动断开连接，等待2秒后重试...');
            // 使用延迟重连，避免立即重连可能导致的循环
            this.reconnectTimer = setTimeout(() => {
              console.log('尝试重新连接...');
              this.socket.connect();
            }, 2000);
          } 
          // 其他原因由socket.io内部处理重连
        });

        this.socket.on('connect_error', (error) => {
          console.error('聊天服务连接错误:', error);
          this.isConnecting = false;
          clearTimeout(timeoutId);
          reject(error);
        });

        this.socket.on('unauthorized', async (error) => {
          console.error('WebSocket认证失败:', error);
          
          // 尝试刷新令牌
          try {
            const newToken = await this.refreshToken();
            
            // 使用新令牌重新连接
            if (this.socket) {
              this.socket.disconnect();
            }
            
            // 短暂延迟后重连
            setTimeout(() => {
              this.connect(this.userId, this.userRole);
            }, 1000);
            
          } catch (refreshError) {
            console.error('刷新令牌失败:', refreshError);
            this.emit('error', { type: 'auth', message: '认证失败，请重新登录' });
          }
        });

        this.socket.on('force_disconnect', (reason) => {
          console.log('服务器要求断开连接:', reason);
          this.connected = false;
          this.isConnecting = false;
          clearInterval(this.heartbeatInterval);
          this.heartbeatInterval = null;
          if (this.socket) {
            this.socket.close();
            this.socket = null;
          }
        });
      });

    } catch (error) {
      this.isConnecting = false;
      console.error('初始化聊天服务失败:', error);
      throw error;
    }
  }

  // 添加心跳检测方法
  setupHeartbeat() {
    // 清除任何现有的心跳检测
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
    
    // 设置新的心跳间隔，每45秒发送一次
    this.heartbeatInterval = setInterval(() => {
      if (this.connected && this.socket) {
        console.log('发送心跳包...');
        this.socket.emit('ping', { timestamp: Date.now() }, (response) => {
          if (response && response.success) {
            console.log('收到心跳响应:', response);
            this.lastPingTime = Date.now();
          } else {
            console.warn('心跳响应失败:', response);
            // 如果心跳响应失败，检查连接状态
            this.checkConnectionHealth();
          }
        });
      } else {
        console.warn('无法发送心跳包，连接已断开');
        this.checkConnectionHealth();
      }
    }, 45000); // 45秒，与服务器的pingInterval匹配
  }

  // 添加连接健康检查方法
  checkConnectionHealth() {
    // 如果最后一次成功ping的时间超过2分钟，或者连接已断开
    if (!this.connected || (this.lastPingTime && Date.now() - this.lastPingTime > 120000)) {
      console.warn('检测到连接异常，尝试重新连接...');
      // 清除现有心跳
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
      
      // 如果连接还存在，先断开
      if (this.socket) {
        this.socket.removeAllListeners();
        try {
          this.socket.close();
        } catch (e) {
          console.error('关闭旧连接时出错:', e);
        }
        this.socket = null;
      }
      
      this.connected = false;
      
      // 延迟重连，避免立即重连可能导致的问题
      if (this.userId && this.userRole) {
        setTimeout(() => {
          this.connect(this.userId, this.userRole).catch(err => {
            console.error('重新连接失败:', err);
          });
        }, 3000);
      }
    }
  }

  // 添加 token 检查方法
    async checkAndRefreshTokenIfNeeded() {
      let token = localStorage.getItem('jwt_token');
      
      if (!token) {
        return await this.refreshToken();
      }
      
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const payload = JSON.parse(window.atob(base64));
        
        // 如果 token 将在 5 分钟内过期，提前刷新
        if (payload.exp && payload.exp * 1000 < Date.now() + 5 * 60 * 1000) {
          console.log('Token 即将过期，主动刷新');
          return await this.refreshToken();
        }
        
        return token;
      } catch (e) {
        console.warn('Token 解析失败，尝试刷新:', e);
        return await this.refreshToken();
      }
    }


  /**
   * 撤回消息
   * @param {string} messageId - 要撤回的消息ID
   * @returns {Promise<Object>} 撤回结果
   */
  async recallMessage(messageId) {
    if (!this.connected || !this.socket) {
      await this.connect(this.userId, this.userRole);
    }

    return new Promise((resolve, reject) => {
      this.socket.emit('chat:recall_message', { messageId }, (response) => {
        if (response && response.success) {
          console.log('消息撤回成功:', response);
          
          // 从响应中获取撤回的消息数据
          const recalledMessage = response.data;
          
          // 更新本地缓存中的消息
          this.updateMessageInCache(messageId, {
            recalled: true,
            recalledAt: recalledMessage.recalledAt || new Date().toISOString(),
            // 可以选择保留原始内容或替换为固定文本
            content: "Message recalled" // 或保留 recalledMessage.content
          });
          
          // 触发消息撤回事件，通知UI更新
          this.emit('message_recalled', { 
            messageId, 
            recalledAt: recalledMessage.recalledAt,
            sender: recalledMessage.sender
          });
          
          resolve(response);
        } else {
          console.error('消息撤回失败:', response?.error || '未知错误');
          reject(new Error(response?.error || '撤回消息失败'));
        }
      });
    });
  }

  /**
   * 更新本地缓存中的消息
   * @param {string} messageId - 消息ID
   * @param {Object} updates - 要更新的字段
   */
  updateMessageInCache(messageId, updates) {
    // 遍历所有会话缓存
    const cacheKeys = Object.keys(localStorage)
      .filter(key => key.startsWith('chat_history_'));
    
    cacheKeys.forEach(cacheKey => {
      try {
        // 获取缓存的消息
        const cachedMessages = JSON.parse(localStorage.getItem(cacheKey) || '[]');
        
        // 查找要更新的消息
        const messageIndex = cachedMessages.findIndex(msg => msg.messageId === messageId);
        
        if (messageIndex !== -1) {
          // 更新消息
          cachedMessages[messageIndex] = {
            ...cachedMessages[messageIndex],
            ...updates
          };
          
          // 保存回缓存
          localStorage.setItem(cacheKey, JSON.stringify(cachedMessages));
          
          console.log(`已更新缓存中的消息 ${messageId}，缓存键: ${cacheKey}`);
          
          // 同时更新内存中的消息缓存（如果存在）
          const conversationId = cacheKey.replace('chat_history_', '');
          if (this.messageCache && this.messageCache[conversationId]) {
            const memCacheIndex = this.messageCache[conversationId]
              .findIndex(msg => msg.messageId === messageId);
            
            if (memCacheIndex !== -1) {
              this.messageCache[conversationId][memCacheIndex] = {
                ...this.messageCache[conversationId][memCacheIndex],
                ...updates
              };
            }
          }
        }
      } catch (error) {
        console.error(`更新缓存消息失败 (${cacheKey}):`, error);
      }
    });
}

  // 设置聊天事件监听器
  setupChatEventListeners() {
    if (this.eventRegistered) return;
    
    // 新消息事件
    this.socket.on('chat:new_message', (message) => {
      console.log('收到新消息:', message);
      
      // 将新消息添加到缓存
      if (message.conversationId) {
        this.addMessageToCache(message.conversationId, message);
      } else if (message.sender && message.receiver) {
        // 尝试构建会话ID
        const isFromAdmin = message.senderRole === 'admin';
        const conversationId = isFromAdmin 
          ? `admin_${message.sender}_teacher_${message.receiver}`
          : `admin_${message.receiver}_teacher_${message.sender}`;
        this.addMessageToCache(conversationId, message);
      }
      
      this.emit('new_message', message);
    });

    // 管理员状态事件 - 存储并转发
    this.socket.on('chat:admin_status', (data) => {
      const { admins } = data;
      console.log('收到管理员列表和状态:', admins);
      // 转发给组件使用
      this.emit('admin_status', admins);
    });

    // 管理员状态变化事件
    this.socket.on('chat:admin_status_change', (data) => {
      const { adminId, adminName, isOnline } = data;
      console.log(`管理员 ${adminName} (${adminId}) ${isOnline ? '上线' : '下线'}`);
      // 转发状态变化
      this.emit('admin_status_change', data);
    });

    // 消息撤回事件
    this.socket.on('message_recalled', (data) => {
      console.log('收到消息撤回事件:', data);
      const { messageId } = data;
      
      // 更新本地缓存中的消息
      this.updateMessageInCache(messageId, {
        recalled: true,
        content: "此消息已被撤回"
      });
      
      // 触发事件通知UI更新
      this.emit('message_recalled', data);
    });

    // 消息状态更新事件
    this.socket.on('chat:message_status', (statusUpdate) => {
    console.log('单条消息状态更新:', statusUpdate);

    // 更新本地缓存中的消息状态
    if (statusUpdate && statusUpdate.messageId) {
      this.updateMessageInCache(statusUpdate.messageId, {
        status: statusUpdate.status,
        readAt: statusUpdate.readAt || Date.now()
      });
    }

    // 转发事件给UI组件
    this.emit('message_status', statusUpdate);
    });

    // 整个会话已读状态事件 - 操作发起者接收的确认
    this.socket.on('chat:read_receipt', (data) => {
    console.log('会话已读确认:', data);

    if (data && data.conversationId) {
      this.updateConversationReadStatus(data.conversationId, data.timestamp);
      this.emit('conversation_read_receipt', data);
    }
    });

    // 会话已读通知 - 消息发送者接收的通知
    this.socket.on('chat:conversation_read', (data) => {
    console.log('会话已读通知:', data);

    if (data && data.conversationId) {
      this.updateConversationReadStatus(data.conversationId, data.timestamp);
      this.emit('conversation_read', data);
    }
    });

    // 消息撤回事件
    this.socket.on('chat:message_recalled', (data) => {
      console.log('消息已被撤回:', data);
      this.emit('message_recalled', data);
    });

    // 错误处理
    this.socket.on('error', (error) => {
      console.error('聊天服务错误:', error);
      this.emit('error', error);
    });

    this.eventRegistered = true;
  }


    /**
   * 获取会话列表
   * @returns {Promise<Array>} 会话列表
   */
    async getConversations() {
      if (!this.connected) {
        await this.connect(this.userId, this.userRole);
      }
  
      return new Promise((resolve, reject) => {
        this.socket.emit('chat:get_conversations', (response) => {
          if (response && response.success) {
            console.log('获取会话列表成功:', response.conversations);
            resolve(response.conversations);
          } else {
            console.error('获取会话列表失败:', response?.error);
            reject(new Error(response?.error || '获取会话列表失败'));
          }
        });
      });
    }
  

    


    /**
 * 获取未读消息数量
 * @returns {Promise<Object>} 未读消息数量信息
 */
    async getUnreadCount() {
      if (!this.connected) {
        await this.connect(this.userId, this.userRole);
      }

      return new Promise((resolve, reject) => {
        this.socket.emit('chat:get_unread_count', (response) => {
          if (response && response.success) {
            console.log('获取未读消息数量成功:', response.unreadCount);
            resolve(response.unreadCount);
          } else {
            console.error('获取未读消息数量失败:', response?.error);
            reject(new Error(response?.error || '获取未读消息数量失败'));
          }
        });
      });
    }

    /**
   * 获取历史消息
   * @param {Object} params - 参数对象
   * @param {string} params.conversationId - 会话ID (格式: admin_adminId_teacher_teacherId)
   * @param {number} params.limit - 限制返回的消息数量，默认50
   * @param {string} params.beforeMessageId - 获取此消息ID之前的消息
   * @param {boolean} params.forceRefresh - 是否强制从服务器刷新，默认false
   * @returns {Promise<Object>} 包含消息和分页信息的对象
   */
    async getMessageHistory({ conversationId, limit = 50, beforeMessageId = null, forceRefresh = false, includeUnread = true } = {}) {
      // 构建标准会话ID
      const isAiConversation = conversationId && conversationId.includes('ai-assistant');
  
      // 构建标准会话ID
      let chatId = conversationId;
      
      // 处理会话ID中可能包含的chat_history_前缀
      if (chatId && chatId.startsWith('chat_history_')) {
        chatId = chatId.substring(12); // 移除前缀
      }
      
      if (isAiConversation) {
        // AI对话直接从本地存储加载，不需要从服务器获取
        const standardAiConversationId = `teacher_${this.userId}_ai-assistant`;
        const cachedMessages = this.loadMessagesFromCache(standardAiConversationId);
        
        console.log(`AI对话从本地缓存加载了 ${cachedMessages.length} 条消息`);
        
        // 直接返回缓存中的消息，不尝试从服务器获取
        return {
          messages: cachedMessages,
          pagination: {
            currentPage: 1,
            totalPages: 1,
            totalMessages: cachedMessages.length
          },
          fromCache: true,
          hasMore: false, // AI对话不支持从服务器加载更多
          hasUnread: false // AI对话不支持消息已读/未读状态
        };
      }
      if (!chatId) {
        // 根据用户角色构建正确的会话ID
        if (this.userRole === 'admin') {
          chatId = `admin_${this.userId}_teacher_${this.selectedTeacherId || ''}`;
        } else {
          chatId = `admin_${this.selectedAdminId || ''}_teacher_${this.userId}`;
        }
      } else if (!chatId.startsWith('admin_')) {
        // 尝试标准化会话ID
        const parts = chatId.split('_');
        if (parts.length === 2) {
          // 根据用户角色确定正确的格式
          if (this.userRole === 'admin') {
            chatId = `admin_${this.userId}_teacher_${parts[0]}`;
          } else {
            chatId = `admin_${parts[1]}_teacher_${this.userId}`;
          }
        }
      }
      
      console.log(`获取历史消息，会话ID: ${chatId}，强制刷新: ${forceRefresh}，包含未读: ${includeUnread}`);
      
      // 尝试从两种可能的会话ID格式中加载消息
      let cachedMessages = [];
      let hasUnreadMessages = false;
      
      if (!forceRefresh && !beforeMessageId) {
        // 尝试从正确格式的会话ID加载
        cachedMessages = this.loadMessagesFromCache(chatId);
        
        // 如果没有找到消息，尝试从颠倒角色的会话ID加载
        if (cachedMessages.length === 0) {
          // 提取adminId和teacherId
          const match = chatId.match(/admin_(\d+)_teacher_(\d+)/);
          if (match) {
            const [_, adminId, teacherId] = match;
            // 尝试颠倒的格式
            const alternativeId = `admin_${teacherId}_teacher_${adminId}`;
            console.log(`尝试从颠倒角色的会话ID加载: ${alternativeId}`);
            const altMessages = this.loadMessagesFromCache(alternativeId);
            if (altMessages.length > 0) {
              console.log(`从颠倒角色的会话ID加载了 ${altMessages.length} 条消息`);
              cachedMessages = altMessages;
              // 可选：将消息从旧格式迁移到新格式
              this.saveMessagesToCache(chatId, altMessages);
            }
          }
        }
        
        // 检查是否有未读消息
        if (cachedMessages.length > 0) {
          // 检查最后一条消息是否未读且不是自己发送的
          const lastMessage = cachedMessages[cachedMessages.length - 1];
          if (lastMessage && 
              lastMessage.sender !== this.userId && 
              (!lastMessage.status || lastMessage.status !== 'read')) {
            hasUnreadMessages = true;
            console.log('缓存中有未读消息');
          }
        }
        
        // 如果有缓存消息且不需要获取未读消息或没有未读消息，直接返回缓存
        if (cachedMessages.length > 0 && (!includeUnread || !hasUnreadMessages)) {
          console.log(`使用缓存的 ${cachedMessages.length} 条消息`);
          return {
            messages: cachedMessages,
            pagination: {
              currentPage: 1,
              totalPages: 1,
              totalMessages: cachedMessages.length
            },
            fromCache: true,
            hasMore: true, // 假设总是有更多历史消息可加载
            hasUnread: hasUnreadMessages
          };
        }
      }
      
      // 如果缓存中没有或需要强制刷新或有beforeMessageId或有未读消息，从服务器获取
      if (!this.connected) {
        console.log('聊天服务未连接，尝试连接...');
        await this.connect(this.userId, this.userRole);
      }

      console.log(`从服务器获取历史消息，会话ID: ${chatId}，限制: ${limit}，之前消息ID: ${beforeMessageId || '无'}`);
      
      return new Promise((resolve, reject) => {
        this.socket.emit('chat:get_history', {
          conversationId: chatId,
          limit,
          beforeMessageId,
          includeUnread: includeUnread // 告诉服务器需要包含未读消息
        }, (response) => {
          if (response && response.success) {
            console.log(`获取历史消息成功，返回 ${response.messages?.length || 0} 条消息`);
            console.log('服务器返回的消息:', JSON.stringify(response.messages));
            
            // 检查是否有新消息
            let hasNewMessages = false;
            if (cachedMessages.length > 0 && response.messages && response.messages.length > 0) {
              // 获取缓存中最新消息的时间
              const latestCachedTime = new Date(cachedMessages[cachedMessages.length - 1].timestamp);
              console.log(`缓存中最新消息时间: ${latestCachedTime.toISOString()}`);
              
              // 检查服务器返回的消息中是否有比缓存更新的
              for (const msg of response.messages) {
                const msgTime = new Date(msg.timestamp);
                if (msgTime > latestCachedTime) {
                  console.log(`发现新消息，ID: ${msg.messageId}，时间: ${msgTime.toISOString()}`);
                  hasNewMessages = true;
                  break;
                }
              }
            } else if (response.messages && response.messages.length > 0) {
              // 如果缓存为空但服务器有消息，则认为有新消息
              hasNewMessages = true;
              console.log('缓存为空但服务器有消息，标记为有新消息');
            }
            
            // 保存到缓存（只有第一页才保存到缓存）
            if (!beforeMessageId && response.messages && response.messages.length > 0) {
              console.log(`保存 ${response.messages.length} 条服务器消息到缓存`);
              this.saveMessagesToCache(chatId, response.messages);
            }
            
            resolve({
              messages: response.messages,
              pagination: response.pagination || {
                currentPage: 1,
                totalPages: 1,
                totalMessages: response.messages.length
              },
              hasMore: response.messages.length >= limit, // 如果返回的消息数量等于limit，说明可能还有更多
              hasNewMessages: hasNewMessages, // 标记是否有新消息
              hasUnread: response.hasUnread || false // 从服务器响应中获取是否有未读消息
            });
          } else {
            console.error('获取历史消息失败:', response?.error);
            reject(new Error(response?.error || '获取历史消息失败'));
          }
        });
      });
    }

    /**
   * 获取管理员状态
   * @returns {Promise<Array>} 管理员状态数组
   */
    async getAdminStatus() {
      if (!this.connected) {
        await this.connect(this.userId, this.userRole);
      }
  
      return new Promise((resolve, reject) => {
        this.socket.emit('chat:get_admin_status', { adminIds: [] }, (response) => {
          if (response && response.success) {
            console.log('获取管理员状态成功:', response.adminStatus);
            resolve(response.adminStatus);
          } else {
            console.error('获取管理员状态失败:', response?.error);
            reject(new Error(response?.error || '获取管理员状态失败'));
          }
        });
      });
    }
    /**
     * 获取老师状态
     * @param {Array<string>} teacherIds - 老师ID数组
     * @returns {Promise<Array>} 老师状态数组
     */
    async getTeacherStatus(teacherIds) {
      if (!this.connected) {
        await this.connect(this.userId, this.userRole);
      }
  
      return new Promise((resolve, reject) => {
        this.socket.emit('chat:get_teacher_status', { teacherIds }, (response) => {
          if (response && response.success) {
            console.log('获取老师状态成功:', response.teacherStatus);
            resolve(response.teacherStatus);
          } else {
            console.error('获取老师状态失败:', response?.error);
            reject(new Error(response?.error || '获取老师状态失败'));
          }
        });
      });
    }


      /**
   * 标记会话为已读
   * @param {string} conversationId - 会话ID
   */
  async markConversationAsRead(conversationId) {
    if (!this.connected) {
      await this.connect(this.userId, this.userRole);
    }

    return new Promise((resolve, reject) => {
      this.socket.emit('chat:mark_as_read', { conversationId }, (response) => {
        if (response && response.success) {
          console.log('标记会话已读成功');
          resolve();
        } else {
          console.error('标记会话已读失败:', response?.error);
          reject(new Error(response?.error || '标记会话已读失败'));
        }
      });
    });
  }


  // 发送消息
  // // 发送消息
  // async sendMessage(messageData) {
  //   if (!this.connected || !this.socket) {
  //     console.log('重新连接聊天服务...');
  //     if (!this.userId || !this.userRole) {
  //       console.error('无法重连：缺少用户信息');
  //       throw new Error('Missing user information for reconnection');
  //     }
  //     await this.connect(this.userId, this.userRole);
  //   }
    
  //   const completeMessageData = {
  //     ...messageData,
  //     sender: this.userId,
  //     senderRole: this.userRole,
  //     timestamp: new Date().toISOString(),
  //     messageId: messageData.messageId || `msg_${Date.now()}`,
  //     conversationId: conversationId,
  //     // 确保有元数据字段
  //     metadata: messageData.metadata || {}
  //   };
  
  //   return new Promise((resolve, reject) => {
  //     try {
  //       if (!this.socket) {
  //         throw new Error('Socket connection not available');
  //       }
    
  //       this.socket.emit('chat:send_message', completeMessageData, (response) => {
  //         if (response && response.success) {
  //           console.log('消息发送成功:', completeMessageData);
            
  //           // 保存成功发送的消息到缓存
  //           const conversationId = messageData.conversationId || 
  //             `admin_${messageData.receiver}_teacher_${this.userId}`;
  //           this.addMessageToCache(conversationId, completeMessageData);
            
  //           resolve(completeMessageData);
  //         } else {
  //           console.error('消息发送失败:', response?.message);
  //           reject(new Error(response?.message || '发送消息失败'));
  //         }
  //       });
  //     } catch (error) {
  //       console.error('发送消息时发生错误:', error);
  //       reject(error);
  //     }
  //   });
  // }

  async sendMessage(messageData) {
    try {
      // 1. 检查并刷新 token（如果需要）
      await this.checkAndRefreshTokenIfNeeded();
  
      // 2. 检查连接状态并重连
      if (!this.connected || !this.socket) {
        console.log('重新连接聊天服务...');
        if (!this.userId || !this.userRole) {
          console.error('无法重连：缺少用户信息');
          throw new Error('Missing user information for reconnection');
        }
        await this.connect(this.userId, this.userRole);
      }
      
      // 3. 确保有会话ID
      const conversationId = messageData.conversationId || 
        `admin_${messageData.receiver}_teacher_${this.userId}`;
      
      // 4. 构建完整的消息数据
      const completeMessageData = {
        ...messageData,
        sender: this.userId,
        senderRole: this.userRole,
        timestamp: new Date().toISOString(),
        messageId: messageData.messageId || `msg_${Date.now()}`,
        conversationId: conversationId,
        metadata: messageData.metadata || {}
      };
    
      // 5. 发送消息并处理响应
      return new Promise((resolve, reject) => {
        try {
          if (!this.socket) {
            throw new Error('Socket connection not available');
          }
      
          this.socket.emit('chat:send_message', completeMessageData, async (response) => {
            if (response && response.success) {
              console.log('消息发送成功:', completeMessageData);
              
              // 保存成功发送的消息到缓存
              this.addMessageToCache(conversationId, completeMessageData);
              
              resolve(completeMessageData);
            } else {
              // 如果失败可能是 token 过期，尝试刷新后重试
              if (response?.error === 'unauthorized' || response?.error === 'token_expired') {
                try {
                  await this.refreshToken();
                  // 重新连接 WebSocket
                  await this.connect(this.userId, this.userRole);
                  // 重试发送消息
                  this.socket.emit('chat:send_message', completeMessageData, (retryResponse) => {
                    if (retryResponse && retryResponse.success) {
                      console.log('重试发送消息成功:', completeMessageData);
                      this.addMessageToCache(conversationId, completeMessageData);
                      resolve(completeMessageData);
                    } else {
                      console.error('重试发送消息失败:', retryResponse?.message);
                      reject(new Error(retryResponse?.message || '重试发送消息失败'));
                    }
                  });
                } catch (refreshError) {
                  console.error('刷新 token 失败:', refreshError);
                  reject(new Error('认证失败，请重新登录'));
                }
              } else {
                console.error('消息发送失败:', response?.message);
                reject(new Error(response?.message || '发送消息失败'));
              }
            }
          });
  
          // 6. 添加超时处理
          setTimeout(() => {
            reject(new Error('发送消息超时'));
          }, 10000); // 10秒超时
  
        } catch (error) {
          console.error('发送消息时发生错误:', error);
          reject(error);
        }
      });
    } catch (error) {
      console.error('发送消息过程中发生错误:', error);
      throw error;
    }
  }
  

  // 获取图片URL（包括缩略图）
  async getImageUrls(fileUrl, messageMetadata) {
    try {
      // 如果消息元数据中包含完整的图片信息，直接使用
      if (messageMetadata?.imageInfo) {
        // 检查token是否过期
        const tokenExpiresAt = messageMetadata.tokenExpiresAt || 0;
        if (Date.now() < tokenExpiresAt) {
          return {
            thumbnailUrl: messageMetadata.imageInfo.thumbnailUrl,
            originalUrl: messageMetadata.imageInfo.originalUrl
          };
        }
      }
      
      // 如果没有元数据或token已过期，尝试刷新token
      try {
        // 从fileInfo中获取fileId
        const fileId = messageMetadata?.fileInfo?.fileId || 
                      fileUrl.split('/').pop().split('?')[0];
        
        // 获取带有新token的访问URL
        const accessUrl = await this.getFileAccessUrl(fileId);
        return {
          thumbnailUrl: accessUrl.replace('.png', '_thumb.png'),
          originalUrl: accessUrl
        };
      } catch (error) {
        console.warn('刷新文件访问token失败:', error);
        // 如果刷新失败，返回原始URL
        return {
          thumbnailUrl: fileUrl,
          originalUrl: fileUrl
        };
      }
    } catch (error) {
      console.error('获取图片URL失败:', error);
      return {
        thumbnailUrl: fileUrl,
        originalUrl: fileUrl
      };
    }
  }

  async sendFileMessage(file, messageText = '', additionalData = {}, progressCallback) {
    try {
      if (!this.connected || !this.socket) {
        await this.connect(this.userId, this.userRole);
      }
  
      const token = localStorage.getItem('jwt_token');
      if (!token) {
        throw new Error('未找到认证令牌');
      }
  
      // 初始化上传
      const initResponse = await fetch(`${config.API_CHATFILE_URL}/api/upload/init`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Origin': window.location.origin
        },
        credentials: 'include',
        body: JSON.stringify({
          fileName: file.name,
          fileSize: file.size,
          mimeType: file.type,
          userId: this.userId,
          userRole: this.userRole
        })
      });
  
      if (!initResponse.ok) {
        const errorData = await initResponse.json();
        throw new Error(errorData.message || '初始化上传失败');
      }
  
      const { uploadId } = await initResponse.json();
  
      // 分片上传
      const chunkSize = 1024 * 1024; // 1MB
      const totalChunks = Math.ceil(file.size / chunkSize);
  
      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(file.size, start + chunkSize);
        const chunk = file.slice(start, end);
        
        const formData = new FormData();
        formData.append('chunk', chunk);
        formData.append('uploadId', uploadId);
        formData.append('chunkIndex', i);
        formData.append('totalChunks', totalChunks);
        formData.append('userId', this.userId);
        formData.append('userRole', this.userRole);
  
        const chunkResponse = await fetch(`${config.API_CHATFILE_URL}/api/upload/chunk`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Origin': window.location.origin
          },
          credentials: 'include',
          body: formData
        });
  
        if (!chunkResponse.ok) {
          const errorData = await chunkResponse.json();
          throw new Error(`分片 ${i + 1}/${totalChunks} 上传失败: ${errorData.message}`);
        }
  
        // 更新进度
        if (progressCallback) {
          progressCallback((i + 1) / totalChunks * 100);
        }
      }
  
      // 完成上传
      const completeResponse = await fetch(`${config.API_CHATFILE_URL}/api/upload/complete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Origin': window.location.origin
        },
        credentials: 'include',
        body: JSON.stringify({
          uploadId,
          fileName: file.name,
          mimeType: file.type,
          userId: this.userId,
          userRole: this.userRole
        })
      });
  
      if (!completeResponse.ok) {
        const errorData = await completeResponse.json();
        throw new Error(errorData.message || '完成上传失败');
      }
  
      const uploadResult = await completeResponse.json();
      console.log("文件上传完成，服务器返回:", uploadResult);
  
      // 确定会话ID
      const conversationId = additionalData.conversationId || 
        `admin_${additionalData.receiver}_teacher_${this.userId}`;
  
      // 构造消息数据，保留完整的URL和所有重要信息
      const messageData = {
        ...additionalData,
        conversationId: conversationId,
        messageType: file.type.startsWith('image/') ? 'image' : 'file',
        content: messageText,
        // 文件基本信息
        fileUrl: uploadResult.fileUrl || uploadResult.fullFileUrl,
        fileName: uploadResult.fileName || file.name,
        fileSize: uploadResult.fileSize || file.size,
        fileMimeType: uploadResult.fileMimeType || file.type,
        // 添加文件ID到顶层，方便访问
        fileId: uploadResult.fileId,
        // 精简的元数据
        metadata: {
          fileId: uploadResult.fileId,
          tokenExpiresAt: Date.now() + (uploadResult.expiresIn || 2592000) * 1000
        },
        messageId: `file_${Date.now()}`
      };
  
      // 如果是图片，添加图片信息
      if (file.type.startsWith('image/') && uploadResult.imageInfo) {
        // 直接添加缩略图URL到消息对象
        messageData.thumbnailUrl = uploadResult.imageInfo.thumbnailUrl;
        
        // 同时也保存到metadata中
        messageData.metadata.thumbnailUrl = uploadResult.imageInfo.thumbnailUrl;
        
        // 添加图片尺寸信息
        if (uploadResult.imageInfo.width && uploadResult.imageInfo.height) {
          messageData.metadata.width = uploadResult.imageInfo.width;
          messageData.metadata.height = uploadResult.imageInfo.height;
        }
      }
  
      console.log("准备发送文件消息:", messageData);
      
      // 发送消息
      return await this.sendMessage(messageData);
  
    } catch (error) {
      console.error('文件上传失败:', error);
      throw error;
    }
  }


// 获取文件访问URL (刷新token)
async getFileAccessUrl(fileUrl) {
  try {
    // 从文件URL中提取文件ID
    const fileId = fileUrl.split('/').pop().split('?')[0];
    
    const token = localStorage.getItem('jwt_token');
    // 获取新的访问令牌
    const response = await fetch(`${config.API_CHATFILE_URL}/api/files/token/${fileId}`, {
      method: 'GET',
      headers: {
        'Authorization': token ? `Bearer ${token}` : ''
      }
    }).then(res => res.json());
    
    if (!response.success) {
      throw new Error(response.message || '获取文件访问令牌失败');
    }
    
    // 构建新的URL（移除旧token，添加新token）
    const baseUrl = fileUrl.split('?')[0];
    return `${baseUrl}?token=${response.accessToken}`;
  } catch (error) {
    console.error('获取文件访问URL失败:', error);
    
    // 如果可能是认证问题，尝试刷新JWT令牌并重试
    try {
      await this.refreshToken();
      // 重试获取访问URL
      return this.getFileAccessUrl(fileUrl);
    } catch (refreshError) {
      console.error('刷新令牌失败，无法获取文件访问权限:', refreshError);
      throw error;
    }
  }
}

// 添加刷新令牌方法
async refreshToken() {
  try {
    const response = await fetch(`${config.API_CHATFILE_URL}/check_auth.php`, {
      method: 'GET',
      credentials: 'include',
    });
    
    const authData = await response.json();
    if (authData.authenticated && authData.token) {
      localStorage.setItem('jwt_token', authData.token);
      console.log('JWT令牌已刷新');
      return authData.token;
    } else {
      throw new Error('无法刷新JWT令牌');
    }
  } catch (error) {
    console.error('刷新令牌失败:', error);
    throw error;
  }
}

  // 断开连接 - 更新方法确保清理所有资源
  disconnect() {
    console.log('主动断开连接');
    this.connected = false;
    
    // 清理所有定时器
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
    
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer);
      this.reconnectTimer = null;
    }
    
    if (this.socket) {
      this.socket.removeAllListeners();
      this.socket.close();
      this.socket = null;
    }

    this.eventRegistered = false;
  }

  removeListener(event, listener) {
    if (typeof listener !== 'function') {
      console.warn(`Attempted to remove a non-function listener for event '${event}'`);
      return this;
    }
    
    return super.removeListener(event, listener);
  }

  /**
   * 更新会话中所有消息的已读状态
   * @param {string} conversationId - 会话ID
   * @param {string|number} timestamp - 已读时间戳
   */
  updateConversationReadStatus(conversationId, timestamp) {
    if (!conversationId) return;
    
    // 获取本地缓存键
    const cacheKey = `chat_history_${conversationId}`;
    
    try {
      // 获取缓存的消息
      const cachedMessages = JSON.parse(localStorage.getItem(cacheKey) || '[]');
      let updated = false;
      
      // 更新每条消息的状态
      const updatedMessages = cachedMessages.map(msg => {
        // 只更新由当前用户发送且未标记为已读的消息
        if (msg.sender === this.userId && (!msg.status || msg.status === 'sent' || msg.status === 'delivered')) {
          updated = true;
          return {
            ...msg,
            status: 'read',
            readAt: timestamp || Date.now()
          };
        }
        return msg;
      });
      
      if (updated) {
        // 保存回缓存
        localStorage.setItem(cacheKey, JSON.stringify(updatedMessages));
        console.log(`已更新会话 ${conversationId} 的所有消息为已读状态`);
        
        // 同时更新内存中的消息缓存
        if (this.messageCache && this.messageCache[conversationId]) {
          this.messageCache[conversationId] = this.messageCache[conversationId].map(msg => {
            if (msg.sender === this.userId && (!msg.status || msg.status === 'sent' || msg.status === 'delivered')) {
              return {
                ...msg,
                status: 'read',
                readAt: timestamp || Date.now()
              };
            }
            return msg;
          });
        }
        
        // 触发消息状态更新事件，通知UI更新
        this.emit('conversation_messages_read', {
          conversationId,
          timestamp: timestamp || Date.now()
        });
      }
    } catch (error) {
      console.error(`更新会话消息状态失败 (${conversationId}):`, error);
    }
  }


   /**
   * 与AI助手进行对话
   * @param {string} text - 文本内容
   * @param {File[]} files - 文件数组（可选）
   * @returns {Promise<Object>} AI响应数据
   */
   async chatWithGemini(text, files) {
    try {
      // 先构建并缓存用户发送的消息
      const userMessage = {
        messageId: `user_${Date.now()}`,
        sender: this.userId,
        senderRole: 'teacher',
        receiver: 'ai-assistant',
        receiverRole: 'ai',
        content: text,
        timestamp: new Date().toISOString(),
        messageType: files && files.length > 0 ? 'file' : 'text',
        // 如果有文件，添加文件信息
        ...(files && files.length > 0 && {
          files: Array.from(files).map(file => ({
            name: file.name || 'unknown',
            size: file.size || 0,
            type: file.type || 'application/octet-stream',
            // 添加更多文件信息
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate?.toISOString()
          }))
        })
      };
      
      // 使用统一的会话ID格式保存用户消息
      const conversationId = `teacher_${this.userId}_ai-assistant`;
      this.addMessageToCache(conversationId, userMessage);
      
      // 获取令牌并确保其有效
      let token = localStorage.getItem('jwt_token');
      if (!token) {
        try {
          token = await this.refreshToken();
        } catch (refreshError) {
          console.error('获取认证令牌失败:', refreshError);
          throw new Error('无法获取有效的认证令牌');
        }
      }
  
      const formData = new FormData();
      
      // 添加文本内容
      if (text) {
        formData.append('text', text);
      }
      
      // 添加文件（如果有）
      if (files && files.length) {
        for (let i = 0; i < files.length; i++) {
          formData.append('files', files[i]);
        }
      }
  
      // 添加用户角色标识
      formData.append('userRole', 'teacher');
      formData.append('userId', this.userId);
  
      // 发送请求
      let response = await fetch(`${config.API_CHATFILE_URL}/api/translate/chat`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Origin': window.location.origin
        },
        body: formData
      });
  
      // 如果返回401，可能是令牌过期，尝试刷新令牌后重试
      if (response.status === 401) {
        try {
          token = await this.refreshToken();
          // 使用新令牌重试请求
          response = await fetch(`${config.API_CHATFILE_URL}/api/translate/chat`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Origin': window.location.origin
            },
            body: formData
          });
        } catch (refreshError) {
          console.error('刷新令牌失败:', refreshError);
          throw new Error('认证失败，请重新登录');
        }
      }
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `请求失败: ${response.status}`);
      }
  
      const result = await response.json();
      
      if (result.success) {
        // 构建AI消息，包含图片信息
        const aiMessage = {
          messageId: `ai_${Date.now()}`,
          sender: 'ai-assistant',
          senderRole: 'ai',
          receiver: this.userId,
          receiverRole: 'teacher',
          content: result.data.response, // 使用 response 而不是 message
          timestamp: new Date().toISOString(),
          messageType: result.data.chatImages?.length ? 'image' : 'text',
          // 如果有图片，添加图片信息
          ...(result.data.chatImages?.length && {
            images: result.data.chatImages,
            fileTypes: result.data.fileTypes,
            filesProcessed: result.data.filesProcessed
          })
        };
        
        // 同样使用统一的会话ID格式保存AI回复
        this.addMessageToCache(conversationId, aiMessage);
        
        return result.data;
      } else {
        throw new Error(result.message || '聊天请求失败');
      }
    } catch (error) {
      console.error('与AI助手对话失败:', error);
      throw error;
    }
  }

  async gradeAssignment(commentsId, additionalInstructions = '') {
    // 添加一个简单的防重复提交机制
    const requestKey = `grading_${commentsId}`;
    
    // 如果已经有相同的请求正在处理中，直接返回
    if (this._pendingGradeRequests && this._pendingGradeRequests[requestKey]) {
      console.log('已有相同的批改请求正在处理中，跳过重复请求:', commentsId);
      return this._pendingGradeRequests[requestKey];
    }
    
    // 创建一个Promise并存储起来
    if (!this._pendingGradeRequests) this._pendingGradeRequests = {};
    
    try {
      const gradePromise = this._doGradeAssignment(commentsId, additionalInstructions);
      this._pendingGradeRequests[requestKey] = gradePromise;
      
      // 无论Promise是否resolve或reject，都要删除该请求
      const cleanup = () => {
        delete this._pendingGradeRequests[requestKey];
      };
      
      // 确保清理工作会被执行
      gradePromise.then(cleanup).catch(cleanup);
      
      return gradePromise;
    } catch (error) {
      delete this._pendingGradeRequests[requestKey];
      throw error;
    }
  }
  
  // 实际执行批改的私有方法
  async _doGradeAssignment(commentsId, additionalInstructions = '') {
    try {
      console.log('==== AI批改开始 ====');
      console.log('输入参数:', { commentsId, additionalInstructions });
      
      // 获取并检查 token
      const token = await this.checkAndRefreshTokenIfNeeded();
      if (!token) {
        console.error('无法获取有效的认证令牌');
        throw new Error('无法获取有效的认证令牌');
      }
      
      // 准备表单数据
      const formData = new FormData();
      formData.append('comments_id', commentsId);
      
      // 添加额外指示(如果有)
      if (additionalInstructions) {
        formData.append('additional_instructions', additionalInstructions);
      }
      
      // 调用 API
      console.log('发送API请求...');
      const response = await fetch(`${config.API_CHATFILE_URL}/api/translate/grade`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });
      
      console.log('收到响应状态:', response.status, response.statusText);
      
      // 处理响应
      if (!response.ok) {
        const errorData = await response.json();
        console.error('请求失败:', errorData);
        throw new Error(errorData.message || `请求失败: ${response.status}`);
      }
      
      const result = await response.json();
      
      if (result.success) {
        console.log('批改成功, 返回数据:', JSON.stringify(result.data, null, 2));
        
        // 直接使用后端返回的数据，不再进行解析
        const gradeData = result.data;
        
        // 存储结果到缓存
        this.saveAIGradeResultToCache(commentsId, gradeData);
        
        // 触发批改完成事件
        this.emit('ai_grade_complete', {
          commentsId: commentsId,
          data: gradeData
        });
        
        console.log('==== AI批改结束 ====');
        return gradeData;
      } else {
        console.error('批改失败:', result.message);
        throw new Error(result.message || 'AI批改请求失败');
      }
    } catch (error) {
      console.error('AI批改异常:', error.message, error.stack);
      // 如果是令牌问题，尝试刷新令牌后重试
      if (error.message.includes('认证') || error.message.includes('token')) {
        console.log('检测到认证问题，尝试刷新令牌...');
        try {
          await this.refreshToken();
          console.log('令牌刷新成功，重试批改操作');
          
          // 重新执行批改逻辑，而不是递归调用gradeAssignment
          // 这样不会重复创建和跟踪Promise
          return await this._doGradeAssignment(commentsId, additionalInstructions);
        } catch (refreshError) {
          console.error('刷新令牌失败:', refreshError);
          throw new Error('认证失败，请重新登录');
        }
      }
      console.log('==== AI批改结束(出错) ====');
      throw error;
    }
  }



/**
 * 强制刷新AI批改结果，获取新版本
 * @param {string} commentsId - 评论ID
 * @param {string} additionalInstructions - 额外的批改指令（可选）
 * @returns {Promise<Object>} 新的批改数据
 */
async refreshGradeResult(commentsId, additionalInstructions = '') {
  try {
    console.log('==== 强制刷新AI批改开始 ====');
    console.log('评论ID:', commentsId);
    console.log('额外指令:', additionalInstructions || '无');
    
    // 获取并检查 token
    const token = await this.checkAndRefreshTokenIfNeeded();
    if (!token) {
      console.error('无法获取有效的认证令牌');
      throw new Error('无法获取有效的认证令牌');
    }
    
    // 准备请求体
    const requestBody = {};
    if (additionalInstructions) {
      requestBody.additional_instructions = additionalInstructions;
    }
    
    // 调用刷新接口（现在直接返回新的批改结果）
    const response = await fetch(`${config.API_CHATFILE_URL}/api/translate/grade/${commentsId}/refresh`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Origin': window.location.origin
      },
      body: JSON.stringify(requestBody)
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      console.error('刷新请求失败:', errorData);
      throw new Error(errorData.message || `请求失败: ${response.status}`);
    }
    
    const result = await response.json();
    
    if (result.success) {
      console.log('成功获取新的批改结果，版本:', result.data.version);
      
      // 清除旧缓存
      this.clearAIGradeResultCache(commentsId);
      
      // 保存新结果到缓存
      this.saveAIGradeResultToCache(commentsId, result.data);
      
      // 触发批改完成事件，让UI组件更新
      this.emit('ai_grade_complete', {
        commentsId: commentsId,
        data: result.data
      });
      
      return {
        success: true,
        message: '已获取新版本批改',
        data: result.data
      };
    } else {
      console.error('批改刷新失败:', result.message);
      throw new Error(result.message || '刷新AI批改结果失败');
    }
  } catch (error) {
    console.error('刷新AI批改异常:', error.message);
    if (error.message.includes('已达到最大批改次数限制')) {
      return {
        success: false,
        message: '已达到最大批改次数限制(3次)',
        error: 'MAX_LIMIT_REACHED'
      };
    }
    throw error;
  }
}
  /**
   * 清除AI批改结果缓存
   * @param {string} commentsId - 评论ID
   */
  clearAIGradeResultCache(commentsId) {
    try {
      if (!commentsId) return;
      
      const cacheKey = `ai_grade_${commentsId}`;
      localStorage.removeItem(cacheKey);
      
      console.log(`已清除AI批改结果缓存，键: ${cacheKey}`);
    } catch (error) {
      console.error('清除AI批改结果缓存失败:', error);
    }
  }

  // 移除 parseGradeResult 函数，因为后端已经处理好了数据
  
  // 新增：保存AI批改结果到缓存
  saveAIGradeResultToCache(commentsId, result) {
    try {
      if (!commentsId || !result) return;
      
      const cacheKey = `ai_grade_${commentsId}`;
      localStorage.setItem(cacheKey, JSON.stringify({
        data: result,
        timestamp: Date.now()
      }));
      
      console.log(`AI批改结果已缓存，键: ${cacheKey}`);
    } catch (error) {
      console.error('缓存AI批改结果失败:', error);
    }
  }
  
  // 从缓存获取AI批改结果
  getAIGradeResult(commentsId) {
    try {
      if (!commentsId) {
        console.warn('获取AI批改结果失败: 未提供 commentsId');
        return { success: false, message: '未提供评论ID' };
      }
      
      const cacheKey = `ai_grade_${commentsId}`;
      console.log(`尝试从缓存获取AI批改结果: ${cacheKey}`);
      const cachedData = localStorage.getItem(cacheKey);
      
      if (cachedData) {
        try {
          const parsed = JSON.parse(cachedData);
          // 检查缓存是否过期（24小时）
          if (parsed.timestamp && Date.now() - parsed.timestamp < 24 * 60 * 60 * 1000) {
            console.log(`从缓存加载AI批改结果: ${cacheKey}`);
            
            // 确保返回的数据格式正确
            if (parsed.data) {
              return {
                success: true,
                data: parsed.data
              };
            } else {
              // 如果缓存数据没有预期结构，包装一下再返回
              if (Array.isArray(parsed)) {
                // 处理数组格式数据
                return {
                  success: true,
                  data: {
                    current: parsed[0], // 假设第一个元素是最新的
                    history: parsed,
                    has_history: parsed.length > 1
                  }
                };
              } else {
                // 处理单个对象格式数据
                return {
                  success: true,
                  data: {
                    current: parsed,
                    history: [parsed],
                    has_history: false
                  }
                };
              }
            }
            } else {
            console.log(`AI批改结果缓存已过期: ${cacheKey}`);
            localStorage.removeItem(cacheKey);
          }
        } catch (parseError) {
          console.error('解析缓存的AI批改结果失败:', parseError);
          localStorage.removeItem(cacheKey);
        }
      } else {
        console.log(`未找到缓存的AI批改结果: ${cacheKey}`);
      }
      
      return { 
        success: false, 
        message: '未找到批改结果' 
      };
    } catch (error) {
      console.error('获取缓存的AI批改结果失败:', error);
      return { 
        success: false, 
        message: '获取批改结果时发生错误', 
        error: error.message 
      };
    }
  }


  //   /**
  //  * 对AI批改版本进行评分
  //  * @param {string|number} gradeId - 批改ID
  //  * @param {number} rating - 评分值(1-5)
  //  * @returns {Promise<Object>} 评分结果
  //  */
  // async rateGradeVersion(gradeId, rating) {
  //   try {
  //     console.log('==== 开始给批改版本评分 ====');
  //     console.log('批改ID:', gradeId);
  //     console.log('评分值:', rating);
      
  //     // 验证评分值
  //     const ratingNum = parseInt(rating);
  //     if (isNaN(ratingNum) || ratingNum < 1 || ratingNum > 5) {
  //       throw new Error('评分必须是1-5之间的整数');
  //     }
      
  //     // 获取并检查 token
  //     const token = await this.checkAndRefreshTokenIfNeeded();
  //     if (!token) {
  //       console.error('无法获取有效的认证令牌');
  //       throw new Error('无法获取有效的认证令牌');
  //     }
      
  //     // 调用评分接口
  //     const response = await fetch(`${config.API_CHATFILE_URL}/api/translate/grade/${gradeId}/rate`, {
  //       method: 'POST',
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //         'Content-Type': 'application/json',
  //         'Origin': window.location.origin
  //       },
  //       body: JSON.stringify({ rating: ratingNum })
  //     });
      
  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       console.error('评分请求失败:', errorData);
  //       throw new Error(errorData.message || `请求失败: ${response.status}`);
  //     }
      
  //     const result = await response.json();
      
  //     if (result.success) {
  //       console.log('批改评分成功:', result.message);
        
  //       // 触发评分完成事件，让UI组件可以更新
  //       this.emit('grade_rated', {
  //         gradeId,
  //         rating: ratingNum,
  //         data: result.data
  //       });
        
  //       return {
  //         success: true,
  //         message: result.message || '评分已保存',
  //         data: result.data
  //       };
  //     } else {
  //       console.error('批改评分失败:', result.message);
  //       throw new Error(result.message || '评分AI批改结果失败');
  //     }
  //   } catch (error) {
  //     console.error('评分过程中出现异常:', error.message);
  //     throw error;
  //   }
  // }

  /**
   * 开始新的AI聊天对话
   * @returns {Promise<Object>} 新会话信息
   */
  async newChat() {
    try {
      // 构建AI对话的标准会话ID
      const aiConversationId = `teacher_${this.userId}_ai-assistant`;
      
      // 清除本地缓存
      localStorage.removeItem(`chat_history_${aiConversationId}`);
      
      // 清除内存缓存
      if (this.messageCache && this.messageCache[aiConversationId]) {
        delete this.messageCache[aiConversationId];
      }
      
      // 获取令牌并确保其有效
      let token = localStorage.getItem('jwt_token');
      if (!token) {
        try {
          token = await this.refreshToken();
        } catch (refreshError) {
          console.error('获取认证令牌失败:', refreshError);
          throw new Error('无法获取有效的认证令牌');
        }
      }
      
      // 调用后端清除历史记录接口
      let response = await fetch(`${config.API_CHATFILE_URL}/api/translate/chat/history`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Origin': window.location.origin,
          'X-User-Role': 'teacher',
          'X-User-Id': this.userId
        }
      });

      // 如果返回401，尝试刷新令牌后重试
      if (response.status === 401) {
        try {
          token = await this.refreshToken();
          // 使用新令牌重试请求
          response = await fetch(`${config.API_CHATFILE_URL}/api/translate/chat/history`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              'Origin': window.location.origin,
              'X-User-Role': 'teacher',
              'X-User-Id': this.userId
            }
          });
        } catch (refreshError) {
          console.error('刷新令牌失败:', refreshError);
          throw new Error('认证失败，请重新登录');
        }
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `清除聊天历史失败: ${response.status}`);
      }
      
      const result = await response.json();
      console.log('已开始新的AI对话，历史记录已清除');
      
      // 触发新对话事件
      this.emit('new_ai_chat_started', {
        conversationId: aiConversationId,
        timestamp: Date.now()
      });
      
      return {
        success: true,
        message: result.message || '聊天历史已清除',
        conversationId: aiConversationId,
        sessionId: `session_${Date.now()}`
      };
    } catch (error) {
      console.error('开始新AI对话失败:', error);
      return {
        success: false,
        message: error.message || '开始新对话失败，但已清除本地缓存',
        error: error.message
      };
    }
  }


  async rateMultipleVersions(ratings) {
    try {
      console.log('==== 开始批量评分批改版本 ====');
      console.log('评分数据:', JSON.stringify(ratings));
      
      // 获取并检查 token
      const token = await this.checkAndRefreshTokenIfNeeded();
      if (!token) {
        console.error('无法获取有效的认证令牌');
        throw new Error('无法获取有效的认证令牌');
      }
      
      // 调用批量评分接口
      let response = await fetch(`${config.API_CHATFILE_URL}/api/translate/grade/rate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Origin': window.location.origin
        },
        body: JSON.stringify({ ratings })
      });
      
      // 如果返回401，尝试刷新令牌后重试
      if (response.status === 401) {
        console.log('令牌无效，尝试刷新...');
        try {
          const newToken = await this.refreshToken();
          console.log('令牌已刷新，重试评分请求');
          
          // 使用新令牌重试请求
          response = await fetch(`${config.API_CHATFILE_URL}/api/translate/grade/rate`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${newToken}`,
              'Origin': window.location.origin
            },
            body: JSON.stringify({ ratings })
          });
        } catch (refreshError) {
          console.error('刷新令牌失败:', refreshError);
          throw new Error('认证失败，请重新登录');
        }
      }
      
      // 处理响应
      if (!response.ok) {
        const errorData = await response.json();
        console.error('批量评分请求失败:', errorData);
        throw new Error(errorData.message || `请求失败: ${response.status}`);
      }
      
      const result = await response.json();
      
      if (result.success) {
        console.log('批量评分成功:', result.message || '评分已保存');
        
        // 触发评分完成事件
        this.emit('grades_rated', {
          ratingsCount: ratings.length,
          data: result.data
        });
        
        return result;
      } else {
        console.error('批量评分失败:', result.message);
        throw new Error(result.message || '批量评分失败');
      }
    } catch (error) {
      console.error('批量评分过程中出现异常:', error);
      throw error;
    }
  }
  
}

// 创建单例实例
const chatService = new ChatService();
export default chatService;