import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MessageSquare, Maximize, Minimize, X as XIcon, Paintbrush , Check, Undo2, RefreshCw,Ban,Clock, X, Smile, Image as ImageIcon, Send, Loader, Download, AlertCircle, File, FileText, Image, FileSpreadsheet, Archive, Music, Video, Paperclip } from 'lucide-react';
import EmojiPicker from './EmojiPicker';
import chatService from '../services/chatSV';
import { compressImage } from '../utils/compressImage';
import { formatFileSize } from '../utils/formatters';
import { uploadFileInChunks } from '../utils/chunkedUpload';
import { loadAvatarImage } from '../utils/avatarLoader';  
import config from '../config';
import ImageViewer from './ImageViewer';
import moment from 'moment';
import './ChatWidget.css';
import ai from '../assets/icon/ai.png';

// 常量定义
const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
const ALLOWED_FILE_TYPES = [
  // 图片类型
  'image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml', 'image/bmp',
  
  // 文档类型
  'application/pdf', 'application/msword', 
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'text/plain', 'text/csv', 'text/html',
  
  // 压缩文件
  'application/zip', 'application/x-zip-compressed', 'application/x-rar-compressed', 'application/vnd.rar',
  
  // 视频文件
  'video/mp4', 'video/webm', 'video/ogg', 'video/quicktime', 'video/x-msvideo', 'video/x-matroska',
  
  // 音频文件
  'audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/webm', 'audio/aac', 'audio/flac'
];

// 允许的视频类型
const ALLOWED_VIDEO_TYPES = [
  'video/mp4', 'video/webm', 'video/ogg', 'video/quicktime', 'video/x-msvideo', 'video/x-matroska'
];

// 允许的音频类型
const ALLOWED_AUDIO_TYPES = [
  'audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/webm', 'audio/aac', 'audio/flac'
];

const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
const COMPRESSION_THRESHOLD = 1 * 1024 * 1024; // 1MB
const CHUNK_THRESHOLD = 5 * 1024 * 1024; // 5MB

function ChatWidget({ user }) {
  // 状态管理
  const [chatOpen, setChatOpen] = useState(false);
  const [chatMinimized, setChatMinimized] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [activeAdmins, setActiveAdmins] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [connectionError, setConnectionError] = useState(null);
  const emojiButtonRef = useRef(null);
  const messageContainerRef = useRef(null);
  const fileInputRef = useRef(null);
  const [adminInfo, setAdminInfo] = useState(null); // 添加管理员信息状态
  const [isInitialized, setIsInitialized] = useState(false);
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    imageUrl: '',
    imageName: '',
    originalUrl: '',
    isOriginal: false
  });
  const textareaRef = useRef(null); 
  const [pastedImage, setPastedImage] = useState(null);
  const [pastedFiles, setPastedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [failedUploadFile, setFailedUploadFile] = useState(null);
  const notificationSoundRef = useRef(null);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    messageId: null,
    canRecall: false
  });

  const [longPressTimer, setLongPressTimer] = useState(null);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const messageHandled = useRef({});
  
  // 添加页面可见性状态
  const [isPageVisible, setIsPageVisible] = useState(true);
  
  // 添加聊天类型状态
  const [chatType, setChatType] = useState('admin'); // 'admin' 或 'ai'
  const [showNewChatButton, setShowNewChatButton] = useState(false);
  const [isAITyping, setIsAITyping] = useState(false);
  const [adminMessages, setAdminMessages] = useState([]);
  const [aiMessages, setAiMessages] = useState([]);
  const [hasMarkedAsRead, setHasMarkedAsRead] = useState(false);
  const messagesEndRef = useRef(null);
  const messagesContentRef = useRef(null);

  

  const scrollToBottom = (force = false) => {
    // 使用现有的 messageContainerRef 而不是 messagesEndRef
    if (!messageContainerRef || !messageContainerRef.current) {
      console.log('messageContainerRef.current 不存在，跳过滚动操作');
      return;
    }
    
    const container = messageContainerRef.current;
    const shouldAutoScroll = force || 
      (container.scrollHeight - container.scrollTop - container.clientHeight < 100);
      
    if (shouldAutoScroll) {
      try {
        // 直接设置 scrollTop 到最大值，实现滚动到底部
        container.scrollTop = container.scrollHeight;
        console.log('已滚动到底部, scrollTop:', container.scrollTop);
      } catch (err) {
        console.error('滚动到底部时出错:', err);
      }
    }
  };
  // 初始化和事件监听
  useEffect(() => {

    console.log('initChat 开始执行，准备加载历史记录');
    if (user?.id && !isInitialized) {
      // console.log('初始化聊天服务，用户信息:', user);
      
      const initChat = async () => {
        try {
          // 连接聊天服务
          await chatService.connect(user.id, user.role);
          setIsInitialized(true);
          
          // 获取管理员消息的逻辑应该在这里或另一个 useEffect 中
          // 比如:
          // const adminMsgs = await chatService.getAdminMessages();
          // setAdminMessages(adminMsgs);
          
          // 在获取完消息后，确保滚动到底部
          // 这里可能需要添加一个短暂延时，确保消息已渲染到DOM
          setTimeout(() => {
            console.log('历史记录加载完成，准备滚动到底部');
            scrollToBottom(true);
          }, 300); // 给予足够时间让消息渲染完成
        } catch (error) {
          // console.error('聊天服务初始化失败:', error);
          setConnectionError(error.message);
          // 5秒后重试
          setTimeout(() => setIsInitialized(false), 5000);
        }
      };
  
      initChat();
    }
  }, [user, isInitialized]); // 只依赖用户信息和初始化状态


  useEffect(() => {
    if (chatType === 'admin' && adminMessages.length > 0) {
      scrollToBottom();
    } else if (chatType === 'ai' && aiMessages.length > 0) {
      scrollToBottom();
    }
  }, [chatType, adminMessages, aiMessages]); // 当消息数组或聊天类型变化时执行


  useEffect(() => {
    if (!isInitialized && user) {
      setIsInitialized(true);
      
      const initChat = async () => {
        // 初始化逻辑...
      };
  
      initChat();
    }
  }, [user, isInitialized]); // 只依赖用户信息和初始化状态

  useEffect(() => {
    // 创建音频元素
    const audio = new Audio('/sounds/notification.mp3'); // 确保这个路径指向您的音频文件
    audio.preload = 'auto';
    notificationSoundRef.current = audio;
    
    return () => {
      // 清理
      if (notificationSoundRef.current) {
        notificationSoundRef.current.pause();
        notificationSoundRef.current = null;
      }
    };
  }, []);


  useEffect(() => {
    // 定义消息处理函数并保存引用

   
    const messageHandler = (message) => {
      // 检查消息是否已处理过
      if (messageHandled.current[message.id]) {
        // console.log('消息已处理过，跳过:', message.id);
        return;
      }
      
      // 标记消息为已处理
      messageHandled.current[message.id] = true;
      
      // 处理消息...
      // setUnreadMessages(prev => {
      //   console.log('未读消息计数从', prev, '增加到', prev + 1);
      //   return prev + 1;
      // });
    };
    
    // 添加事件监听器
    // console.log('添加new_message事件监听器');
    chatService.on('new_message', messageHandler);
    
    // 返回清理函数
    return () => {
      // console.log('移除new_message事件监听器');
      // 使用相同的引用移除监听器
      chatService.removeListener('new_message', messageHandler);
    };
  }, [chatOpen]);


  // 监听管理员状态
  useEffect(() => {
    // 处理管理员状态
    const handleAdminStatus = (admins) => {
      // console.log('接收到管理员状态:', admins);
      setActiveAdmins(admins);
      
      // 如果有管理员且尚未设置adminInfo，则设置第一个管理员
      if (admins && admins.length > 0 && !adminInfo) {
        const admin = admins[0];
        setAdminInfo(admin);
        
        // 获取与该管理员的历史消息
        chatService.getMessageHistory({
          conversationId: `${user.id}_${admin.adminId}`
        }).then(({ messages }) => {
          if (Array.isArray(messages)) {
            // 确保消息按时间顺序排序（旧消息在前，新消息在后）
            const sortedMessages = [...messages].sort((a, b) => 
              new Date(a.timestamp) - new Date(b.timestamp)
            );
            setMessages(sortedMessages);
          }
        }).catch(error => {
          console.error('Get history messages failed:', error);
        });
        
        // 更新未读消息计数
        const totalUnread = admins.reduce((total, admin) => total + (admin.unreadCount || 0), 0);
        setUnreadMessages(totalUnread);

        setTimeout(() => {
          scrollToBottom(true); // 假设有一个 scrollToBottom 函数
        }, 100);
      }
    };
    
    // 处理管理员状态变化
    const handleAdminStatusChange = (data) => {
      // console.log('管理员状态变化:', data);
      // 更新activeAdmins中对应管理员的状态
      setActiveAdmins(prev => prev.map(admin => 
        admin.adminId === data.adminId 
          ? { ...admin, isOnline: data.isOnline }
          : admin
      ));
      
      // 如果是当前选中的管理员，也更新adminInfo
      if (adminInfo && adminInfo.adminId === data.adminId) {
        setAdminInfo(prev => ({ ...prev, isOnline: data.isOnline }));
      }
    };
    
    // 添加事件监听
    chatService.on('admin_status', handleAdminStatus);
    chatService.on('admin_status_change', handleAdminStatusChange);
    
    // 清理事件监听
    return () => {
      chatService.removeListener('admin_status', handleAdminStatus);
      chatService.removeListener('admin_status_change', handleAdminStatusChange);
    };
  }, [user, adminInfo]);


  // 监听新消息
  useEffect(() => {
    const handleNewMessage = (message) => {
      console.log('handleNewMessage被调用:', message, '当前chatType:', chatType, '聊天窗口状态:', chatOpen);
  
      // 根据消息类型和当前聊天模式决定如何处理消息
      if (message.senderRole === 'admin') {
        // 管理员消息
        if (chatType === 'admin' && chatOpen && !chatMinimized) {
          // 当前在管理员聊天界面且窗口打开未最小化，直接更新当前消息列表
          setMessages(prev => {
            const newMessages = [...prev, message];
            const sortedMessages = newMessages.sort((a, b) => 
              new Date(a.timestamp) - new Date(b.timestamp)
            );
            return preprocessMessages(sortedMessages);
          });
        } else {
          // 不在管理员聊天界面或窗口未打开或已最小化
          setAdminMessages(prev => {
            const newMessages = [...prev, message];
            return newMessages.sort((a, b) => 
              new Date(a.timestamp) - new Date(b.timestamp)
            );
          });
          
          // 以下任一条件成立时增加未读计数：
          // 1. 聊天窗口未打开
          // 2. 窗口已最小化
          // 3. 当前不在管理员聊天界面
          // 4. 页面不可见
          if (!chatOpen || chatMinimized || chatType !== 'admin' || !isPageVisible) {
            console.log('增加未读消息计数');
            setUnreadMessages(prev => prev + 1);
          }
        }
      } else if (message.sender === 'ai' || message.sender === 'user') {
        // AI消息处理...（保持不变）
      }
      
      // 如果不是自己发送的消息，播放提示音
      if (message.sender.toString() !== user.id.toString()) {
        // 播放通知声音 - 不管聊天窗口状态如何
        if (notificationSoundRef.current) {
          notificationSoundRef.current.play().catch(error => {
            console.error('Cannot play notification sound:', error);
          });
        }
      }
    };
  
    // 添加事件监听
    chatService.on('new_message', handleNewMessage);
  
    // 清理事件监听
    return () => {
      chatService.removeListener('new_message', handleNewMessage);
    };
  }, [chatMinimized, chatOpen, adminInfo, user, isPageVisible, chatType]);

  // 添加精确控制已读的逻辑 - 只有用户滚动到底部且页面可见时才标记为已读
  useEffect(() => {
    if (!adminInfo || !chatOpen || chatMinimized || !isPageVisible) {
      return;
    }
    
    const conversationId = `${user.id}_${adminInfo.adminId}`;
    const messageContainer = messageContainerRef.current;
    if (!messageContainer) return;
    
    // 监测是否有未读消息
    const hasUnreadMessages = messages.some(msg => 
      msg.sender !== user.id && (!msg.status || msg.status === 'sent')
    );
    
    if (!hasUnreadMessages) return;
    
    // 已读状态追踪变量
    let hasMarkedAsRead = false;
    
    // 函数：检查是否应该标记为已读
    const checkReadStatus = () => {
      // 如果已经标记为已读或没有未读消息，则不需要处理
      if (hasMarkedAsRead || unreadMessages === 0) return;
      
      // 判断是否已滚动到底部附近
      const { scrollTop, scrollHeight, clientHeight } = messageContainer;
      const isNearBottom = (scrollHeight - scrollTop - clientHeight) < 20;
      
      // 用户滚动到底部，视为已阅读
      if (isNearBottom && isPageVisible && chatOpen && !chatMinimized) {
        // console.log('用户已滚动到底部，标记会话为已读');
        
        // 设置一个标志，防止重复标记
        hasMarkedAsRead = true;
        
        // 调用API标记已读
        chatService.markConversationAsRead(conversationId)
          .then(() => {
            // 更新本地未读计数
            setUnreadMessages(0);
          })
          .catch(error => {
            console.error('Mark conversation as read failed:', error);
            hasMarkedAsRead = false; // 失败时重置标志
          });
      }
    };
    
    // 添加滚动事件监听器
    messageContainer.addEventListener('scroll', checkReadStatus);
    
    // 用户可能一开始就在底部，所以初始检查一次
    // 但延迟一点执行，确保界面已渲染完成
    const initialCheckTimer = setTimeout(checkReadStatus, 1500);
    
    return () => {
      clearTimeout(initialCheckTimer);
      messageContainer.removeEventListener('scroll', checkReadStatus);
    };
  }, [adminInfo, messages, chatOpen, chatMinimized, isPageVisible, user.id]);

  const openChat = () => {
    setChatOpen(true);
    setChatMinimized(false);
    setUnreadMessages(0); // 重置未读消息计数
    setTimeout(() => scrollToBottom(true), 100);
    
    // 如果有选中的管理员且页面可见，标记与该管理员的会话为已读
    if (adminInfo && isPageVisible) {
      const conversationId = `admin_${adminInfo.adminId}_teacher_${user.id}`;
      chatService.markConversationAsRead(conversationId).catch(error => {
        console.error('Mark conversation as read failed:', error);
      });
    }
  };


  // 处理消息右键点击
  const handleMessageContextMenu = (e, message) => {

    if (chatType === 'ai') return;
    e.preventDefault(); // 阻止默认右键菜单
    
    // 检查是否是自己发送的消息且在3分钟内
    const messageTime = new Date(message.timestamp);
    const now = new Date();
    const diffMinutes = (now - messageTime) / (1000 * 60);
    
    // 只有自己发送的文本消息且在3分钟内才显示撤回选项
    if (message.sender.toString() === user.id.toString() && 
        message.messageType === 'text' && 
        diffMinutes <= 3) {
      setContextMenu({
        visible: true,
        x: e.clientX,
        y: e.clientY,
        messageId: message.messageId,
        canRecall: true
      });
    } else if (message.sender.toString() === user.id.toString()) {
      // 自己的消息但超过时间限制
      setContextMenu({
        visible: true,
        x: e.clientX,
        y: e.clientY,
        messageId: message.messageId,
        canRecall: false
      });
    }
  };

  // 关闭上下文菜单
  const closeContextMenu = () => {
    setContextMenu({
      visible: false,
      x: 0,
      y: 0,
      messageId: null,
      canRecall: false
    });
  };

  // 撤回消息
  const recallMessage = async (messageId) => {
    if (chatType === 'ai') return;
    try {
      // 先更新UI，显示撤回中状态
      setMessages(prev => 
        prev.map(msg => 
          msg.messageId === messageId 
            ? { ...msg, isRecalling: true } 
            : msg
        )
      );
      
      // 调用服务撤回消息
      await chatService.recallMessage(messageId);
      
      // 更新本地消息列表
      setMessages(prev => 
        prev.map(msg => 
          msg.messageId === messageId 
            ? { ...msg, recalled: true, content: "This message was recalled", isRecalling: false } 
            : msg
        )
      );
      
      // 清除选中状态
      setSelectedMessageId(null);
      
    } catch (error) {
      console.error('Recall message failed:', error);
      
      // 恢复消息状态
      setMessages(prev => 
        prev.map(msg => 
          msg.messageId === messageId 
            ? { ...msg, isRecalling: false } 
            : msg
        )
      );
      
      // 显示错误提示
      setUploadError('Recall message failed: ' + error.message);
    }
  };

  // 添加点击外部关闭上下文菜单的处理
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (contextMenu.visible && !e.target.closest('.context-menu')) {
        closeContextMenu();
      }
    };
    
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [contextMenu.visible]);

  useEffect(() => {
    // 处理消息撤回事件
    const handleMessageRecalled = (data) => {
      const { messageId } = data;
      
      // 更新消息列表
      setMessages(prev => 
        prev.map(msg => 
          msg.messageId === messageId 
            ? { ...msg, recalled: true, content: "This message was recalled" } 
            : msg
        )
      );
    };
    
    // 添加事件监听
    chatService.on('message_recalled', handleMessageRecalled);
    
    // 清理事件监听
    return () => {
      chatService.removeListener('message_recalled', handleMessageRecalled);
    };
  }, []);


  // 添加点击外部关闭上下文菜单的处理
  useEffect(() => {
    const handleClickOutside = () => {
      if (contextMenu.visible) {
        closeContextMenu();
      }
    };
    
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [contextMenu.visible]);


  // 添加点击外部取消选中的处理
  useEffect(() => {
    const handleClickOutside = (e) => {
      // 如果有选中的消息，且点击的不是该消息或其子元素
      if (selectedMessageId && !e.target.closest(`[data-message-id="${selectedMessageId}"]`)) {
        setSelectedMessageId(null);
      }
    };
    
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [selectedMessageId]);

  // const selectAdmin = (admin) => {
  //   setAdminInfo(admin);
    
  //   // 构建会话ID
  //   const conversationId = `admin_${admin.adminId}_teacher_${user.id}`;
    
  //   // 获取与该管理员的历史消息
  //   chatService.getMessageHistory({ conversationId })
  //     .then(({ messages }) => {
  //       if (Array.isArray(messages)) {
  //         // 确保消息按时间顺序排序（旧消息在前，新消息在后）
  //         const sortedMessages = [...messages].sort((a, b) => 
  //           new Date(a.timestamp) - new Date(b.timestamp)
  //         );
  //         setMessages(sortedMessages);
  //       }
        
  //       // 标记为已读
  //       return chatService.markConversationAsRead(conversationId);
  //     })
  //     .catch(error => {
  //       console.error('Get history messages or mark as read failed:', error);
  //     });
  // };

  const handleScroll = () => {
    if (!messageContainerRef.current) return;
    
    const { scrollTop, scrollHeight, clientHeight } = messageContainerRef.current;
    const isAtBottom = scrollHeight - scrollTop - clientHeight < 20; // 接近底部的阈值
    
    // 只有当满足以下条件时才标记为已读：
    // 1. 滚动到底部
    // 2. 存在管理员信息
    // 3. 有未读消息
    // 4. 未被标记为已读
    if (isAtBottom && adminInfo && unreadMessages > 0 && !hasMarkedAsRead) {
      // 设置标记，防止重复调用
      hasMarkedAsRead = true;
      
      // 用户已滚动到底部，标记为已读
      const conversationId = `admin_${adminInfo.adminId}_teacher_${user.id}`;
      chatService.markConversationAsRead(conversationId)
        .then(() => {
          // 更新未读消息计数
          setUnreadMessages(0);
        })
        .catch(error => {
          console.error('Mark conversation as read failed:', error);
          hasMarkedAsRead = false; // 失败时重置标记
        });
    }
  };

  useEffect(() => {
    if (unreadMessages > 0) {
      setHasMarkedAsRead(false);
    }
  }, [unreadMessages]);


  // 加载更多历史消息
  const loadMoreMessages = async () => {
    if (isLoadingMore || !hasMore || !adminInfo) return;
    
    try {
      setIsLoadingMore(true);
      
      // 获取当前显示的最早消息ID
      const oldestMessage = messages[0];
      if (!oldestMessage) {
        setIsLoadingMore(false);
        return;
      }
      
      const conversationId = `admin_${adminInfo.adminId}_teacher_${user.id}`;
      
      // 请求更早的消息
      const result = await chatService.getMessageHistory({
        conversationId,
        limit: 50,
        beforeMessageId: oldestMessage.messageId
      });
      
      if (result.messages && result.messages.length > 0) {
        // 确保新加载的消息也按时间顺序排序
        const sortedNewMessages = [...result.messages].sort((a, b) => 
          new Date(a.timestamp) - new Date(b.timestamp)
        );
        
        // 将新消息添加到列表前面
        setMessages(prev => [...sortedNewMessages, ...prev]);
        // 更新是否还有更多消息
        setHasMore(result.hasMore);
      } else {
        // 没有更多消息了
        setHasMore(false);
      }
    } catch (error) {
      console.error('Load more messages failed:', error);
    } finally {
      setIsLoadingMore(false);
    }
  };
  
  // 在消息容器顶部添加"加载更多"按钮
  const renderLoadMoreButton = () => {
    // 只在管理员模式下显示加载更多按钮
    if (chatType !== 'ai' && hasMore) {
      // 管理员模式下，如果有更多消息可加载
      return (
        <div className="text-center py-2">
          <button 
            onClick={loadMoreMessages}
            disabled={isLoadingMore}
            className="text-sm text-purple-600 hover:text-purple-800 px-4 py-2 rounded-full hover:bg-purple-100 transition-colors"
          >
            {isLoadingMore ? (
              <span className="flex items-center justify-center">
                <RefreshCw size={16} className="animate-spin mr-2" />
                Loading...
              </span>
            ) : (
              <span className="flex items-center justify-center">
                <RefreshCw size={16} className="mr-2" />
                Load More
              </span>
            )}
          </button>
        </div>
      );
    }
    
    return null; // 如果没有更多消息或不符合条件，不渲染任何按钮
  };
  
  // 添加滚动事件监听
  useEffect(() => {
    const container = messageContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [adminInfo]);


  // 处理管理员状态变化
  // 处理管理员状态变化
  useEffect(() => {
    if (!isInitialized || !user?.id) return;
  
    // 监听连接状态变化
    const handleConnectionChange = async (connected) => {
      if (connected && adminInfo) {
        // console.log('聊天服务重新连接，加载历史消息');
        
        try {
          // 构建会话ID
          const conversationId = `admin_${adminInfo.adminId}_teacher_${user.id}`;
          
          // 先从本地缓存加载消息
          const cachedMessages = chatService.loadMessagesFromCache(conversationId);
          if (cachedMessages && cachedMessages.length > 0) {
            // console.log('从本地缓存加载了消息:', cachedMessages.length);
            // 确保消息按时间顺序排序
            const sortedMessages = [...cachedMessages].sort((a, b) => 
              new Date(a.timestamp) - new Date(b.timestamp)
            );
            setMessages(sortedMessages);
          }
          
          // 然后从服务器获取最新消息
          const { messages: serverMessages } = await chatService.getMessageHistory({
            conversationId,
            limit: 50,
            forceRefresh: true // 强制从服务器刷新
          });
          
          if (Array.isArray(serverMessages) && serverMessages.length > 0) {
            // console.log('从服务器获取了最新消息:', serverMessages.length);
            // 确保消息按时间顺序排序
            const sortedMessages = [...serverMessages].sort((a, b) => 
              new Date(a.timestamp) - new Date(b.timestamp)
            );
            setMessages(sortedMessages);
          }
        } catch (error) {
          console.error('重连后加载历史消息失败:', error);
        }
      }
    };
  
    // 添加连接状态变化监听器
    chatService.on('connection_change', handleConnectionChange);
    
    return () => {
      chatService.removeListener('connection_change', handleConnectionChange);
    };
  }, [isInitialized, user?.id, adminInfo]);




  useEffect(() => {
    // 定义监听器函数并保存引用
    const handleMessageStatus = (statusUpdate) => {
      const { conversationId, messageIds, status } = statusUpdate;
      
      // 只处理当前选中的会话
      if (adminInfo && `admin_${adminInfo.adminId}_teacher_${user.id}` === conversationId) {
        // 更新消息状态
        setMessages(prevMessages => 
          prevMessages.map(msg => 
            messageIds.includes(msg.messageId) 
              ? { ...msg, status: status, readAt: statusUpdate.readAt } 
              : msg
          )
        );
      }
    };
    
    // 添加事件监听
    chatService.on('message_status', handleMessageStatus);
    
    // 清理事件监听 - 确保传递相同的函数引用
    return () => {
      chatService.removeListener('message_status', handleMessageStatus);
    };
  }, [adminInfo, user]);


  const updateLocalCache = (conversationId, processedMessages) => {
    try {
      console.log('正在更新本地缓存...', conversationId);
      const cacheKey = `chat_history_${conversationId}`;
      
      // 保存修改后的消息到localStorage
      localStorage.setItem(cacheKey, JSON.stringify(processedMessages));
      
      // 同时更新chatService中的内存缓存
      if (chatService.messageCache) {
        chatService.messageCache[conversationId] = processedMessages;
      }
      
      console.log('本地缓存更新成功，消息数量:', processedMessages.length);
      return true;
    } catch (error) {
      console.error('更新本地缓存失败:', error);
      return false;
    }
  };
  // 强化预处理函数，增加日志，确保能处理AI聊天的消息格式
  // 添加预处理函数来优化消息显示
  const preprocessMessages = (messagesArray, conversationId = null, updateCache = false) => {
    if (!Array.isArray(messagesArray) || messagesArray.length < 2) {
      return messagesArray;
    }
    
    // console.log(`开始预处理 ${messagesArray.length} 条消息...`);
    
    // 创建深拷贝，确保不会修改原始数据
    const processedMessages = JSON.parse(JSON.stringify(messagesArray));
    let processingCount = 0;
    
    // 遍历所有消息，查找AI回复中包含的图片信息
    for (let i = 1; i < processedMessages.length; i++) {
      const currentMsg = processedMessages[i];
      const prevMsg = processedMessages[i-1];
      
      // 检查当前消息是否是AI回复，且包含图片信息
      if (currentMsg.sender === 'ai-assistant' && 
          ((currentMsg.images && currentMsg.images.length > 0) || 
           (currentMsg.chatImages && currentMsg.chatImages.length > 0))) {
        
        // console.log('发现AI回复包含图片');
        
        // 检查前一条消息是否是用户发送的文件类型消息
        if (prevMsg.sender.toString() === user.id.toString() && 
            (prevMsg.messageType === 'file' || 
             (prevMsg.files && prevMsg.files.some(f => f.type && f.type.startsWith('image/'))))) {
          
          // console.log('前一条用户消息是文件或包含图片文件');
          
          // 从AI回复中提取图片信息
          const imageInfo = currentMsg.images?.[0] || currentMsg.chatImages?.[0];
          
          if (imageInfo && imageInfo.fileUrl) {
            // console.log('从AI回复提取到图片信息:', imageInfo);
            
            // 更新用户消息，添加图片URL
            processedMessages[i-1] = {
              ...prevMsg,
              messageType: 'image', // 强制设置为image类型
              fileUrl: imageInfo.fileUrl,
              thumbnailUrl: imageInfo.thumbnailUrl || imageInfo.fileUrl,
              fileName: imageInfo.fileName || prevMsg.files?.[0]?.name || 'image.png',
              // 添加其他有用的信息
              fileId: imageInfo.fileId,
              width: imageInfo.width,
              height: imageInfo.height,
              // 标记这条消息已被回填图片信息
              isBackfilled: true
            };
            
            // console.log('已更新用户消息，添加图片URL');
            
            // 修改AI消息，移除图片信息，保留纯文本回复
            processedMessages[i] = {
              ...currentMsg,
              messageType: 'text',
              // 移除图片相关字段，避免重复显示
              images: undefined,
              chatImages: undefined,
              // 保留文本内容不变
            };
            
            console.log('已更新AI消息，移除图片信息');
            processingCount++;
          }
        }
      }
    }
    
     console.log(`预处理完成，处理了 ${processingCount} 对消息`);
    
    // 如果指定了会话ID并且需要更新缓存，直接更新本地缓存
    if (conversationId && updateCache && processingCount > 0) {
      console.log('正在更新本地缓存...');
      updateLocalCache(conversationId, processedMessages);
    }
    
    return processedMessages;
  };



  useEffect(() => {
    if (chatType === 'ai') {
      setShowNewChatButton(true);
      
      // 使用统一的会话ID格式
      const aiConversationId = `teacher_${user.id}_ai-assistant`;
      
      console.log('Loading AI chat history...', aiConversationId);
      
      // 从本地缓存加载
      try {
        const cachedMessages = chatService.loadMessagesFromCache(aiConversationId);
        
        if (Array.isArray(cachedMessages) && cachedMessages.length > 0) {
          console.log(`Loaded ${cachedMessages.length} AI chat records from cache`);
          
          // 确保消息按时间顺序排序
          const sortedMessages = [...cachedMessages].sort((a, b) => 
            new Date(a.timestamp) - new Date(b.timestamp)
          );
          
          // 预处理消息，第三个参数设为true，表示更新缓存
          const processedMessages = preprocessMessages(sortedMessages, aiConversationId, true);
          
          // 设置到状态
          setMessages(processedMessages);
          
          // 滚动到底部
          setTimeout(() => {
            if (messageContainerRef.current) {
              messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
            }
          }, 100);
        } else {
          // 显示默认欢迎消息
          const welcomeMessage = {
            messageId: `ai_welcome_${Date.now()}`,
            sender: 'ai-assistant',
            senderRole: 'ai',
            receiver: user.id,
            receiverRole: 'teacher',
            content: "Hello! I'm the AI assistant, how can I help you?",
            timestamp: new Date().toISOString(),
            messageType: 'text'
          };
          setMessages([welcomeMessage]);
        }
      } catch (error) {
        console.error('Load AI chat history failed:', error);
      }
    }
  }, [chatType, user.id]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // 只有当拖拽的是文件时才设置拖拽状态
    if (e.dataTransfer.types.includes('Files')) {
      setIsDragging(true);
    }
  };
  
  const handleDragOver = (e) => {
    // 阻止默认行为是必须的，否则无法触发drop事件
    e.preventDefault();
    e.stopPropagation();
    
    // 不需要在这里重复设置isDragging，避免不必要的重渲染
    return false;
  };
  
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // 检查是否真的离开了容器
    // 只有当离开事件的relatedTarget（鼠标进入的新元素）不在容器内时，才算真正离开
    const container = e.currentTarget;
    const relatedTarget = e.relatedTarget;
    
    if (!relatedTarget || !container.contains(relatedTarget)) {
      setIsDragging(false);
    }
  };
  
  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    setIsDragging(false);
    
    if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) return;
    
    // 根据聊天类型处理文件
    if (chatType === 'ai') {
      // 使用文件选择处理函数来处理拖放的文件
      const fakeEvent = { 
        target: { 
          files: e.dataTransfer.files 
        }
      };
      await handleFileSelect(fakeEvent);
    } else {
      // 管理员聊天的拖放文件处理
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        await handleFileUpload(e.dataTransfer.files[i]);
      }
    }
  };


  const getFileIcon = (mimeType) => {
    if (!mimeType) return <File size={24} />;
    
    if (mimeType.startsWith('image/')) {
      return <Image size={24} />;
    } else if (mimeType === 'application/pdf') {
      return <FileText size={24} />;
    } else if (
      mimeType === 'application/msword' || 
      mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      return <FileText size={24} />;
    } else if (
      mimeType === 'application/vnd.ms-excel' || 
      mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      return <FileSpreadsheet size={24} />;
    } else if (
      mimeType === 'application/zip' || 
      mimeType === 'application/x-zip-compressed' ||
      mimeType === 'application/x-rar-compressed' ||
      mimeType === 'application/vnd.rar'
    ) {
      return <Archive size={24} />;
    } else if (mimeType.startsWith('audio/')) {
      return <Music size={24} />;
    } else if (mimeType.startsWith('video/')) {
      return <Video size={24} />;
    } else {
      return <File size={24} />;
    }
  };


  // 发送消息
  const sendMessage = async () => {
    console.log('[调试] 发送消息触发, 包含文件数:', pastedFiles.length);
    // 清除之前的错误提示
    setUploadError(null);
    
    // 修改检查条件：同时考虑 newMessage、pastedImage 和 pastedFiles
    if (!newMessage.trim() && !pastedImage && pastedFiles.length === 0) return;
    
    const messageContent = newMessage.trim();
    setNewMessage('');
    const imageCopy = pastedImage;
    
    try {
      if (chatType === 'ai') {
        // 准备发送给 AI 的文件数组
        let filesToSend = [];
        let unsupportedFiles = [];
        
        // Gemini 支持的文件类型列表
        const supportedMimeTypes = [
          'image/jpeg', 'image/png', 'image/webp', 'image/heic', 'image/heif',
          'application/pdf', 'text/plain', 'text/html', 'text/csv',
          'text/javascript', 'application/json', 'text/css', 'application/xml'
        ];
        
        // 检查文件类型是否被支持
        const isFileSupported = (fileType) => {
          return supportedMimeTypes.some(type => fileType.toLowerCase().includes(type.toLowerCase()));
        };
        
        // 处理粘贴的图片
        if (imageCopy) {
          try {
            let imageFile = null;
            
            // 确保获取到真正的文件对象
            if (imageCopy instanceof File) {
              imageFile = imageCopy;
            } else if (typeof imageCopy === 'string' && imageCopy.startsWith('data:')) {
              // 如果是 data URL，转换为 Blob 然后创建 File 对象
              const res = await fetch(imageCopy);
              const blob = await res.blob();
              imageFile = new File([blob], 'pasted-image.png', { type: blob.type || 'image/png' });
            }
            
            // 检查文件类型是否支持
            if (imageFile && isFileSupported(imageFile.type)) {
              filesToSend.push(imageFile);
            } else if (imageFile) {
              unsupportedFiles.push(imageFile.name || 'pasted-image');
            }
          } catch (error) {
            console.error('处理粘贴图片失败:', error);
            setUploadError('处理粘贴图片失败');
            return;
          }
        }
        
        // 处理粘贴的文件列表
        if (pastedFiles.length > 0) {

          console.log('[调试] 准备上传附带的文件:', pastedFiles.map(f => f.name).join(', '));

          pastedFiles.forEach(fileItem => {
            // 检查是否是有效的文件对象
            if (fileItem.file && typeof fileItem.file === 'object') {
              // 检查文件类型是否支持
              if (isFileSupported(fileItem.file.type)) {
                filesToSend.push(fileItem.file);
              } else {
                unsupportedFiles.push(fileItem.file.name || '未命名文件');
              }
            }
          });
          // 清空粘贴的文件列表
          setPastedFiles([]);
        }
        
        // 如果有不支持的文件，显示错误信息
        if (unsupportedFiles.length > 0) {
          setUploadError(`以下文件类型不被 AI 支持: ${unsupportedFiles.join(', ')}。支持的类型: 图片(jpg/png/webp), PDF, 文本文件。`);
          // 如果没有其他内容可发送，则直接返回
          if (filesToSend.length === 0 && !messageContent.trim()) {
            return;
          }
        }
        
        // 将用户消息立即显示到界面
        const userMessage = {
          messageId: `user_${Date.now()}`,
          sender: user.id,
          senderRole: 'teacher',
          content: messageContent,
          timestamp: new Date().toISOString(),
          messageType: (filesToSend.length > 0) ? 'file' : 'text',
          // 对文件格式的信息添加到消息
          ...(filesToSend.length > 0 && {
            files: filesToSend.map(file => ({
              name: file.name || 'unknown',
              size: file.size || 0,
              type: file.type || 'application/octet-stream',
              lastModified: file.lastModified
            }))
          })
        };
        
        setMessages(prev => [...prev, userMessage]);
        
        // 显示 AI 正在输入状态
        setIsAITyping(true);
        const typingMessageId = `ai_typing_${Date.now()}`;
        const typingMessage = {
          messageId: typingMessageId,
          sender: 'ai-assistant',
          senderRole: 'ai',
          receiver: user.id,
          receiverRole: 'teacher',
          content: "AI is thinking...",
          timestamp: new Date().toISOString(),
          messageType: 'text',
          isTyping: true
        };
        setMessages(prev => [...prev, typingMessage]);
        
        // 调试日志
        console.log(`准备发送给 AI 的文件数量: ${filesToSend.length}`);
        filesToSend.forEach((file, index) => {
          console.log(`文件 ${index + 1}: 名称=${file.name}, 类型=${file.type}, 大小=${file.size} 字节`);
        });
        
        // 调用 AI 服务，传入文本和文件
        const result = await chatService.chatWithGemini(messageContent, filesToSend);
        
        // 移除"正在输入"提示消息
        setMessages(prev => prev.filter(m => m.messageId !== typingMessageId));
        
        // 构建 AI 回复消息
        const aiMessage = {
          messageId: `ai_${Date.now()}`,
          sender: 'ai-assistant',
          senderRole: 'ai',
          receiver: user.id,
          receiverRole: 'teacher',
          content: result.response,
          timestamp: new Date().toISOString(),
          messageType: 'text',
          ...(result.chatImages?.length > 0 && {
            chatImages: result.chatImages,
            fileTypes: result.fileTypes,
            filesProcessed: result.filesProcessed
          })
        };
        
        // 更新本地缓存
        const aiConversationId = `teacher_${user.id}_ai-assistant`;
        const updatedMessages = [...messages.filter(m => !m.isTyping), userMessage, aiMessage];
        const processedMessages = preprocessMessages(updatedMessages, aiConversationId, true);
        updateLocalCache(aiConversationId, processedMessages);
        
        // 立即重新加载历史记录，确保文件消息正确显示
        const historyResult = await chatService.getMessageHistory({
          conversationId: aiConversationId,
          limit: 50,
          forceRefresh: true
        });
        
        if (Array.isArray(historyResult.messages)) {
          const sortedMessages = [...historyResult.messages].sort((a, b) => 
            new Date(a.timestamp) - new Date(b.timestamp)
          );
          const finalMessages = preprocessMessages(sortedMessages, aiConversationId, true);
          setMessages(finalMessages);
        } else {
          // 如果无法获取历史记录，至少更新当前视图
          setMessages(prev => [...prev.filter(m => !m.isTyping), aiMessage]);
        }
        
        setIsAITyping(false);
        setPastedImage(null);
        
      } else {
        // 管理员聊天模式
        if (pastedImage) {
          try {
            let file;
            if (pastedImage.type && pastedImage.name) {
              file = pastedImage;
            } else {
              const blob = await fetch(pastedImage).then(r => r.blob());
              file = new File([blob], 'pasted-image.png', { type: 'image/png' });
            }
            await handleFileUpload(file, messageContent);
            setPastedImage(null);
          } catch (error) {
            console.error('Upload pasted image failed:', error);
            setUploadError('Upload image failed');
            return;
          }
        }
        
        // 如果有粘贴的文件，则逐个上传，并清空 pastedFiles
        if (pastedFiles.length > 0) {
          for (const fileItem of pastedFiles) {
            await handleFileUpload(fileItem.file);
          }
          setPastedFiles([]);
        } else {
          // 如果只是单纯的文本消息，则发送文本消息
          const targetAdmin = adminInfo || activeAdmins[0];
          if (!targetAdmin) {
            console.error('No admin information found, cannot send message');
            setUploadError('No admin connection, please try again later');
            return;
          }
    
          const messageData = {
            receiver: targetAdmin.adminId,
            receiverRole: 'admin',
            conversationType: 'admin_chat',
            messageType: 'text',
            content: messageContent,
          };
    
          const response = await chatService.sendMessage(messageData);
          setMessages(prev => [...prev, response]);
        }
      }
    
      // 清除输入框和粘贴图片状态，并滚动到底部
      setNewMessage('');
      setPastedImage(null);
      setTimeout(() => {
        if (messageContainerRef.current) {
          messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
      }, 100);
    } catch (error) {
      console.error('Send message failed:', error);
      setUploadError(error.message);
    }
  };


  // 在初始化聊天或切换管理员时滚动到底部
  useEffect(() => {
    if (adminInfo && messages.length > 0) {
      setTimeout(() => {
        if (messageContainerRef.current) {
          messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
      }, 100);
    }
  }, [adminInfo, messages]);
  // 处理文件选择

  const handleFileSelect = async (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    
    // 为AI聊天和管理员聊天使用不同的处理方式
    if (chatType === 'ai') {
      // AI聊天的文件处理 - 直接使用chatWithGemini
      try {
        // 检查文件大小限制
        const MAX_FILE_SIZE = 5 * 1024 * 1024; // 10MB
        const filesArray = Array.from(files);
        const oversizedFiles = filesArray.filter(file => file.size > MAX_FILE_SIZE);
        
        if (oversizedFiles.length > 0) {
          const oversizedNames = oversizedFiles.map(f => f.name).join(', ');
          setUploadError(`The following files exceed the 5MB limit: ${oversizedNames}`);
          return;
        }
        
        // 显示用户消息
        const userMessage = {
          messageId: `user_${Date.now()}`,
          sender: user.id,
          senderRole: 'teacher',
          content: newMessage.trim(),
          timestamp: new Date().toISOString(),
          messageType: 'file',
          fileName: files[0].name
        };
        setMessages(prev => [...prev, userMessage]);
        
        // 显示AI正在思考
        setIsAITyping(true);
        const typingMessageId = `ai_typing_${Date.now()}`;
        const typingMessage = {
          messageId: typingMessageId,
          sender: 'ai-assistant',
          senderRole: 'ai',
          receiver: user.id,
          receiverRole: 'teacher',
          content: "AI is thinking...",
          timestamp: new Date().toISOString(),
          messageType: 'text',
          isTyping: true
        };
        setMessages(prev => [...prev, typingMessage]);
        
        // 发送文件到AI
        const result = await chatService.chatWithGemini(newMessage.trim(), filesArray);
        
        // 移除"正在思考"消息
        setMessages(prev => prev.filter(m => m.messageId !== typingMessageId));
        
        // 添加AI回复
        const aiMessage = {
          messageId: `ai_${Date.now()}`,
          sender: 'ai-assistant',
          senderRole: 'ai',
          receiver: user.id,
          receiverRole: 'teacher',
          content: result.response,
          timestamp: new Date().toISOString(),
          messageType: 'text',
          ...(result.chatImages?.length > 0 && {
            chatImages: result.chatImages,
            fileTypes: result.fileTypes,
            filesProcessed: result.filesProcessed
          })
        };
        setMessages(prev => [...prev, aiMessage]);
        
        // 清空输入框
        setNewMessage('');
      } catch (error) {
        console.error('AI chat file processing failed:', error);
        setUploadError(error.message || 'File processing failed');
      } finally {
        setIsAITyping(false);
      }
    } else {
      // 管理员聊天的文件处理 - 使用现有的handleFileUpload
      for (let i = 0; i < files.length; i++) {
        await handleFileUpload(files[i]);
      }
    }
    
    // 清空input以允许选择相同的文件
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };


  const handleAIFileUpload = async (files) => {
    // 检查文件大小，限制在10MB以内
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 10MB
    
    // 转换为数组以便处理
    const filesArray = Array.from(files);
    
    // 验证文件大小
    const oversizedFiles = filesArray.filter(file => file.size > MAX_FILE_SIZE);
    if (oversizedFiles.length > 0) {
      const oversizedNames = oversizedFiles.map(f => f.name).join(', ');
      setUploadError(`The following files exceed the 5MB limit: ${oversizedNames}`);
      return;
    }
    
    // 显示文件正在处理
    setIsUploading(true);
    
    try {
      // 直接使用chatWithGemini API发送文件和消息
      const response = await chatService.chatWithGemini(
        newMessage, 
        filesArray
      );
      
      // 清空消息输入框
      setNewMessage('');
      setIsUploading(false);
      
      // 如果有错误消息，显示错误
      if (!response.success && response.message) {
        setUploadError(response.message);
      }
    } catch (error) {
      console.error('AI chat file upload failed:', error);
      setIsUploading(false);
      setUploadError(error.message || 'File upload failed, please try again later');
    }
  };

  // 处理文件上传
const handleFileUpload = async (file) => {
  console.log('[调试] 开始上传文件:', file.name, '类型:', file.type, '大小:', file.size);
  // 清除之前的错误提示
  setUploadError(null);

  if (chatType === 'ai') {
    console.warn('[调试] AI聊天模式不应使用handleFileUpload函数');
    return;
  }
  
    // 检查文件大小
    if (file.size > MAX_FILE_SIZE) {
      setUploadError(`文件过大，最大支持 ${formatFileSize(MAX_FILE_SIZE)}`);
      return;
    }
    
    // 检查文件类型
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      setUploadError('不支持的文件类型');
      return;
    }
    
    try {
      // 创建临时消息
      const tempMessageId = `temp_${Date.now()}`;
      const isImage = ALLOWED_IMAGE_TYPES.includes(file.type);
      const isCompressedFile = file.type === 'application/zip' || 
                            file.type === 'application/x-zip-compressed' ||
                            file.type === 'application/x-rar-compressed' ||
                            file.type === 'application/vnd.rar';
      
      // 添加临时消息
      const tempMessage = {
        messageId: tempMessageId,
        sender: user.id,
        content: '',
        timestamp: Date.now(),
        messageType: isImage ? 'image' : 'file_uploading',
        fileName: file.name,
        fileSize: file.size,
        fileMimeType: file.type,
        isUploading: true,
        uploadProgress: 0,
        isLoading: isImage, // 图片需要加载
      };
      
      setMessages(prev => [...prev, tempMessage]);
      
      // 滚动到底部
      setTimeout(() => {
        if (messageContainerRef.current) {
          messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
      }, 100);
      
      let fileToUpload = file;
      let thumbnailBlob = null;
      
      // 如果是图片且大于压缩阈值，则压缩
      if (isImage && file.size > COMPRESSION_THRESHOLD) {
        try {
          const compressedImage = await compressImage(file, {
            maxWidth: 1200,
            maxHeight: 1200,
            quality: 0.8
          });
          
          // 创建缩略图
          thumbnailBlob = await compressImage(file, {
            maxWidth: 300,
            maxHeight: 300,
            quality: 0.7
          });
          
          fileToUpload = compressedImage;
        } catch (error) {
          console.error('图片压缩失败:', error);
          // 压缩失败，使用原图
          fileToUpload = file;
        }
      }
      
      // 上传文件
      let uploadResult;
      
      // 大文件使用分块上传 - 修复这里的问题
      if (fileToUpload.size > CHUNK_THRESHOLD) {
        // 使用 chatService.sendFileMessage 替代 uploadFileInChunks
        const messageText = newMessage.trim();
        uploadResult = await chatService.sendFileMessage(fileToUpload, messageText, {
          receiver: adminInfo?.adminId,
          receiverRole: 'admin',
          conversationType: 'admin_chat'
        }, (progress) => {
          // 更新临时消息的进度
          setMessages(prev => prev.map(msg => 
            msg.messageId === tempMessageId 
              ? { ...msg, uploadProgress: progress } 
              : msg
          ));
        });
      } else {
        // 小文件使用普通上传
        const messageText = newMessage.trim();
        uploadResult = await chatService.sendFileMessage(fileToUpload, messageText, {
          receiver: adminInfo?.adminId,
          receiverRole: 'admin',
          conversationType: 'admin_chat'
        }, (progress) => {
          // 更新临时消息的进度
          setMessages(prev => prev.map(msg => 
            msg.messageId === tempMessageId 
              ? { ...msg, uploadProgress: progress } 
              : msg
          ));
        });
      }
      
      // 上传成功，更新消息
      if (uploadResult && (uploadResult.fileUrl || uploadResult.messageId)) {
        // 创建消息对象
        const messageData = {
          content: newMessage.trim(),
          messageType: isImage ? 'image' : 'file',
          fileUrl: uploadResult.fileUrl,
          fileName: file.name,
          fileSize: file.size,
          fileMimeType: file.type,
          messageId: uploadResult.messageId || tempMessageId
        };
        
        // 如果有缩略图URL，添加到消息中
        if (uploadResult.thumbnailUrl) {
          messageData.thumbnailUrl = uploadResult.thumbnailUrl;
        }
        
        // 更新消息列表，替换临时消息
        setMessages(prev => prev.map(msg => 
          msg.messageId === tempMessageId 
            ? { 
                ...msg, 
                ...messageData,
                isUploading: false,
                uploadProgress: 100,
                timestamp: uploadResult.timestamp || msg.timestamp
              } 
            : msg
        ));
        
        // 清空输入框
        setNewMessage('');
        // 清除粘贴的图片
        setPastedImage(null);
      }
    } catch (error) {
      console.error('文件上传失败:', error);
      
      // 保存失败的文件，以便重试
      setFailedUploadFile(file);
      
      // 更新临时消息状态为失败
      setMessages(prev => prev.map(msg => 
        msg.messageId.startsWith('temp_') 
          ? {
              ...msg, 
              isUploading: false, 
              error: '上传失败，请重试',
              failedFile: file // 保存失败的文件引用
            } 
          : msg
      ));
      
      setUploadError('文件上传失败，请重试');
    }
  };


  const retryFileUpload = async (failedMessage) => {
    if (!failedMessage || !failedUploadFile) {
      return;
    }
    
    // 清除错误
    setUploadError('');
    
    // 根据聊天类型重试上传
    if (chatType === 'ai') {
      // AI聊天的文件重试
      handleAIFileUpload([failedUploadFile]);
    } else {
      // 管理员聊天的文件重试
      await handleFileUpload(failedUploadFile);
    }
    
    // 清除失败文件
    setFailedUploadFile(null);
  };



  const handlePaste = async (e) => {
    if (!e.clipboardData) return;
    
    // 检查是否有文件
    const items = e.clipboardData.items;
    if (!items) return;
    
    const newFiles = [];
    
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      
      // 检查是否是文件
      if (item.kind === 'file') {
        const file = item.getAsFile();
        if (!file) continue;
        
        // 检查文件类型是否在允许列表中
        if (ALLOWED_FILE_TYPES.includes(file.type)) {
          e.preventDefault(); // 阻止默认粘贴行为
          
          // 根据文件类型设置不同的提示和图标
          let fileTypeDesc = '文件';
          let fileIcon = <File />;
          
          if (ALLOWED_IMAGE_TYPES.includes(file.type)) {
            fileTypeDesc = '图片';
            fileIcon = <Image />;
          } else if (ALLOWED_VIDEO_TYPES.includes(file.type)) {
            fileTypeDesc = '视频';
            fileIcon = <Video />;
          } else if (ALLOWED_AUDIO_TYPES.includes(file.type)) {
            fileTypeDesc = '音频';
            fileIcon = <Music />;
          } else if (file.type.includes('spreadsheet') || file.type.includes('excel')) {
            fileIcon = <FileSpreadsheet />;
          } else if (file.type.includes('pdf') || file.type.includes('document') || file.type.includes('word')) {
            fileIcon = <FileText />;
          } else if (file.type.includes('zip') || file.type.includes('rar') || file.type.includes('compressed')) {
            fileIcon = <Archive />;
          }
          
          // 添加到新文件数组
          newFiles.push({
            file: file,
            icon: fileIcon,
            typeDesc: fileTypeDesc
          });
        } else {
          // 不支持的文件类型
          setUploadError(`不支持的文件类型: ${file.type}`);
        }
      }
    }
    
    // 如果有新文件，添加到已有文件列表
    if (newFiles.length > 0) {
      setPastedFiles(prev => [...prev, ...newFiles]);
    }
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.addEventListener('paste', handlePaste);
    }
    
    return () => {
      if (textarea) {
        textarea.removeEventListener('paste', handlePaste);
      }
    };
  }, [textareaRef.current]); // 依赖于textareaRef.current

  // 添加上传进度监听
  // 添加上传进度监听
  useEffect(() => {
      const handleUploadProgress = (progress) => {
      setUploadProgress(progress);
      };

      chatService.addListener('upload_progress', handleUploadProgress);

      return () => {
      chatService.removeListener('upload_progress', handleUploadProgress);
      };
  }, []);

    // 处理表情选择
  const handleEmojiSelect = (emojiObject) => {
    setNewMessage(prev => prev + emojiObject.emoji);
    // 选择表情后聚焦输入框
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };



    // 打开图片模态框
    const openImageModal = (imageUrl, imageName, originalUrl = null, isOriginal = false) => {
      setImageModal({
        isOpen: true,
        imageUrl,
        imageName: imageName || 'Image',
        originalUrl: originalUrl || imageUrl, // 直接使用传入的原图URL
        isOriginal
      });
    };

    // 关闭图片模态框
    const closeImageModal = () => {
      setImageModal({
        isOpen: false,
        imageUrl: '',
        imageName: '',
        originalUrl: '',
        isOriginal: false
      });
    };

    const loadOriginalImage = async () => {
      try {
        // 直接使用原图URL，不需要额外处理
        setImageModal(prev => ({
          ...prev,
          imageUrl: prev.originalUrl,
          isOriginal: true
        }));
        
        return imageModal.originalUrl;
      } catch (error) {
        console.error('Load original image failed:', error);
        return null;
      }
    };

  // 切换最小化状态
  const toggleMinimize = () => {
    setChatMinimized(!chatMinimized);
    if (chatMinimized) {
      setUnreadMessages(0);
      setTimeout(() => scrollToBottom(true), 100);
    }
  };

  // 关闭聊天窗口
  const closeChat = () => {
    setChatOpen(false);
    setShowEmojiPicker(false);
  };


  const formatMessageTime = (timestamp) => {
    const messageDate = moment(timestamp);
    const now = moment();
    const diffDays = now.diff(messageDate, 'days');
    
    if (diffDays < 7) {
      // 一周内显示星期几
      if (messageDate.isSame(now, 'day')) {
        return messageDate.format('h:mm A'); // Today at 2:30 PM
      }
      if (messageDate.isSame(now.subtract(1, 'day'), 'day')) {
        return `Yesterday at ${messageDate.format('h:mm A')}`; // Yesterday at 2:30 PM
      }
      return messageDate.format('dddd [at] h:mm A'); // Monday at 2:30 PM
    } else {
      // 超过一周显示具体日期
      return messageDate.format('MMM D [at] h:mm A'); // Jan 15 at 2:30 PM
    }
  };

    /**
   * 获取管理员头像URL
   * @param {string} adminId - 管理员ID
   * @returns {string} 头像URL
   */
    const getAdminAvatarUrl = useCallback((adminId) => {
      return `${config.CHAT_WS_URL.replace('/chatws', '')}/useravatars/${adminId}.jpg`;
    }, [config.CHAT_WS_URL]); // 只依赖于config变化



    /**
   * 渲染消息头像（包括老师和管理员）
   */
 // 2. 使用React.memo包装MessageAvatar组件，避免不必要的重新渲染
  // 2. 使用React.memo包装MessageAvatar组件，避免不必要的重新渲染
  const MessageAvatar = React.memo(({ userId, role, name }) => { // 在这里添加 React.memo
    const [avatarUrl, setAvatarUrl] = useState(
      role === 'admin' 
        ? getAdminAvatarUrl(userId)
        : '/images/default-teacher-avatar.png'
    );

    useEffect(() => {
      const loadAvatar = async () => {
        if (role !== 'admin') {
          try {
            const url = await loadAvatarImage(userId, name);
            setAvatarUrl(url);
          } catch (error) {
            console.error('Error loading teacher avatar:', error);
            setAvatarUrl('/images/default-teacher-avatar.png');
          }
        }
      };

      loadAvatar();
    }, [userId, role, name]); // 依赖项保持不变

    return (
      <div className="w-8 rounded-full overflow-hidden">
        <img
          src={avatarUrl}
          alt={name}
          className="w-full h-full "
          onError={(e) => {
            // 错误处理逻辑保持不变
            if (role === 'admin' && !e.target.src.endsWith('.jpg')) {
              e.target.src = getAdminAvatarUrl(userId).replace('.png', '.jpg');
            } else {
              e.target.src = role === 'admin' 
                ? '/images/default-admin-avatar.png'
                : '/images/default-teacher-avatar.png';
            }
          }}
        />
      </div>
    );
  }); // 结束 React.memo 包装


  /**
   * 渲染管理员头像
   */
  const renderAdminAvatar = useCallback((admin) => (
    <div key={admin.adminId} className="relative w-7 h-7">
      <div className="w-7 h-7 rounded-full overflow-hidden">
        <img
          src={getAdminAvatarUrl(admin.adminId)}
          alt={admin.adminName}
          className="w-full h-full"
          onError={(e) => {
            if (!e.target.src.endsWith('.jpg')) {
              e.target.src = getAdminAvatarUrl(admin.adminId).replace('.png', '.jpg');
            } else {
              e.target.src = '/images/default-admin-avatar.png';
            }
          }}
        />
      </div>
      <div className={`absolute -bottom-0 -right-0 w-2.5 h-2.5 rounded-full border-2 border-white ${
        admin.isOnline ? 'bg-green-400' : 'bg-gray-400'
      }`} />
    </div>
  ), [getAdminAvatarUrl]);

    // 自定义双对勾图标
  const DoubleCheckIcon = ({ size = 14, className = "" }) => (
    <div className={`relative ${className}`} style={{ width: size, height: size }}>
      <Check size={size} className="absolute text-green-600" style={{ left: '-2px' }} />
      <Check size={size} className="absolute text-green-600" style={{ left: '2px' }} />
    </div>
  );

  // 渲染消息
const renderMessage = (msg, index) => {

  if (!msg) return null;

  
  // 确保类型一致性比较
  const isOwn = msg.sender.toString() === user.id.toString();
  const isAI = msg.sender === 'ai-assistant';
  const messageClass = isOwn ? 'justify-end' : 'justify-start';
  
  // 检查消息是否已撤回
  const isRecalled = msg.recalled;
  
  // 检查是否可以撤回（自己发送的文本消息且在3分钟内）
  const messageTime = new Date(msg.timestamp);
  const now = new Date();
  const diffMinutes = (now - messageTime) / (1000 * 60);
  const canRecall = isOwn && msg.messageType === 'text' && diffMinutes <= 3;
  
  // 检查是否是当前选中的消息
  const isSelected = selectedMessageId === msg.messageId;
  
  // 添加日志，帮助调试
  // console.log(`渲染消息: ID=${msg.messageId}, 类型=${msg.messageType}, 发送者=${msg.sender}`, msg);
  
  // 判断是否应该渲染图片
  const shouldRenderImage = (message) => {
    // 图片类型的消息，且有缩略图或原图URL
    if (message.messageType === 'image' && (message.thumbnailUrl || message.fileUrl)) {
      return true;
    }
    
    // 文件类型的消息，但实际是图片文件
    if (message.messageType === 'file' && 
        ((message.files && message.files.some(f => f.type?.startsWith('image/'))) || 
        message.fileMimeType?.startsWith('image/')) && 
        (message.thumbnailUrl || message.fileUrl)) {
      return true;
    }
    
    // 检查是否有isBackfilled标记（预处理添加的）
    if (message.isBackfilled && (message.thumbnailUrl || message.fileUrl)) {
      return true;
    }
    
    return false;
  };


  const renderImageContent = (message) => {
    const imageUrl = message.thumbnailUrl || message.fileUrl;
    const originalUrl = message.fileUrl;
    const imageName = message.fileName || 
                     (message.files && message.files[0] ? message.files[0].name : 'Image');
    
    if (!imageUrl) {
      console.warn('No image URL found:', message);
      return null;
    }
    
    return (
      <div className="cursor-pointer mb-2" onClick={(e) => e.stopPropagation()}>
        <img
          src={imageUrl}
          alt={imageName}
          className="max-w-[200px] rounded-lg"
          onClick={(e) => {
            e.stopPropagation();
            openImageModal(originalUrl, imageName);
          }}
          onError={(e) => {
            console.error('Image loading failed:', imageUrl);
            e.target.src = '/images/image-placeholder.png'; // 替换为你的占位图
          }}
        />
      </div>
    );
  };
  
  // 渲染图片内容
  // const renderMessageContent = (message, isOwn) => {
  //   console.log('[调试] 渲染消息内容, 类型:', message.messageType, '上传状态:', message.isUploading);
  //   // 对于被撤回的消息
  //   if (message.recalled) {
  //     return <p className="text-gray-400 italic text-sm">此消息已被撤回</p>;
  //   }
    
  //   // 对于正在输入的消息
  //   if (message.isTyping) {
  //     return (
  //       <div className="flex">
  //         <div className="typing-indicator">
  //           <span></span><span></span><span></span>
  //         </div>
  //       </div>
  //     );
  //   }
    
  //   // 处理AI聊天中的文件消息 - 关键修复
  //   if (message.messageType === 'file') {
  //     // 判断是否为图片文件
  //     const isImageFile = 
  //       (message.files && message.files.some(f => f.type?.startsWith('image/'))) || 
  //       message.fileMimeType?.startsWith('image/');
      
  //     if (isImageFile && shouldRenderImage(message)) {
  //       return renderImageContent(message);
  //     }
      
  //     // 非图片文件，显示文件信息
  //     return renderFileContent(message);
  //   }
    
  //   // 现有的图片消息处理逻辑
  //   if (shouldRenderImage(message)) {
  //     return renderImageContent(message);
  //   }
    
  //   // 文本消息
  //   return (
  //     <p className="break-words whitespace-pre-wrap">
  //       {message.content || ""}
  //     </p>
  //   );
  // };

  const renderFileContent = (message) => {
    console.log('[调试] 渲染文件内容，消息:', message);
    console.log('[调试] 文件上传状态:', message.isUploading, '进度:', message.uploadProgress);
    
    // 从message中提取文件信息
    let fileName = '';
    let fileSize = '';
    
    // 处理AI聊天中的文件格式
    if (message.files && message.files.length > 0) {
      // AI聊天特有的格式
      fileName = message.files[0].name || 'Unknown file';
      fileSize = message.files[0].size ? formatFileSize(message.files[0].size) : '';
    } else {
      // 常规格式
      fileName = message.fileName || 'Unknown file';
      fileSize = message.fileSize ? formatFileSize(message.fileSize) : '';
    }
    
    // 判断是否正在上传中
    const isUploading = message.isUploading === true;
    const progress = message.uploadProgress || 0;
    
    return (
      <div className="flex flex-col p-2 bg-white rounded-lg shadow-sm mb-2 max-w-[250px]">
        <div className="flex items-center cursor-pointer">
          <div className="text-blue-500 mr-3">
            <FileText size={20} />
          </div>
          <div className="flex-1 overflow-hidden">
            <div className="text-sm font-medium text-gray-700 truncate">{fileName}</div>
            {fileSize && <div className="text-xs text-gray-500">{fileSize}</div>}
          </div>
          <div className="text-blue-500 ml-2">
            {isUploading ? (
              <Loader size={16} className="animate-spin" />
            ) : (
              <Download size={16} />
            )}
          </div>
        </div>
        
        {/* 添加上传进度条 */}
        {isUploading && (
          <div className="mt-2 w-full">
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div 
                className="bg-purple-500 h-2.5 rounded-full transition-all duration-300 ease-in-out" 
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <div className="text-xs text-right mt-1 text-gray-600">
              {Math.round(progress)}%
            </div>
          </div>
        )}
        
        {/* 显示上传错误（如果有） */}
        {message.error && (
          <div className="mt-1 text-xs text-red-500">
            {message.error}
          </div>
        )}
      </div>
    );
  };
  
  // 渲染消息状态指示器（只对自己发送的消息显示）
  const renderMessageStatus = () => {
    if (!isOwn || isRecalled) return null; // 只显示自己发送的且未撤回的消息的状态
    
    // 根据消息状态显示不同的指示器
    switch (msg.status) {
      case 'read':
        return (
          <div className="text-green-500">
            <DoubleCheckIcon size={14} />
          </div>
        );
      case 'delivered':
        return (
          <div className="text-green-500">
            <Check size={14} />
          </div>
        );
      case 'sent':
        return (
          <div className="text-green-500">
            <Check size={14} />
          </div>
        );
      case 'sending':
        return (
          <div className="text-gray-400">
            <div className="w-3 h-3 rounded-full bg-gray-400 animate-pulse"></div>
          </div>
        );
      case 'failed':
        return (
          <div className="text-red-500">
            <AlertCircle size={14} />
          </div>
        );
      default:
        return (
          <div className="text-green-500">
            <Check size={14} />
          </div>
        );
    }
  };

  // 处理AI回复中的用户图片
  // const renderUserImages = (images) => {
  //   if (!images || !images.length) return null;
    
  //   // console.log('渲染AI回复中的用户图片:', images);
    
  //   return (
  //     <div className="mb-2">
  //       {images.map((image, idx) => (
  //         <div key={`img_${idx}`} className="relative mb-2">
  //           <img
  //             src={image.thumbnailUrl}
  //             alt={image.fileName}
  //             onClick={(e) => {
  //               e.stopPropagation();
  //               openImageModal(image.fileUrl, image.fileName);
  //             }}
  //             className="max-w-[200px] rounded-lg cursor-pointer"
  //             onError={(e) => {
  //               console.error('AI reply image loading failed:', image.thumbnailUrl);
  //               e.target.src = '/images/image-placeholder.png';
  //             }}
  //           />
  //         </div>
  //       ))}
  //     </div>
  //   );
  // };
    
  return (
    <div 
      key={msg.messageId || index} 
      className={`flex items-start space-x-2 mb-4 ${messageClass}`}
      onTouchStart={(e) => handleTouchStart(e, msg)}
      onTouchEnd={handleTouchEnd}
      data-message-id={msg.messageId} 
    >
      {/* 头像部分保持不变 */}
      {!isOwn && (
        isAI ? (
          <div className="w-7 h-7 rounded-full overflow-hidden">
            <img 
              src={ai} 
              alt="AI Assistant" 
              className="w-full h-full object-cover"
            />
          </div>
        ) : (
          <MessageAvatar 
            userId={msg.sender}
            role="admin"
            name={adminInfo?.adminName || 'Admin'}
          />
        )
      )}
      
      <div className={`max-w-[70%] ${isOwn ? 'mr-2' : 'ml-2'} relative`}>
        {/* 时间显示部分保持不变 */}
        <div className={`text-xsm text-gray-500 mb-1 ${isOwn ? 'text-right' : 'text-left'}`}>
          {formatMessageTime(msg.timestamp)}
        </div>
        
        <div className={`relative ${isSelected ? 'pr-10' : ''}`}>
          {isRecalled ? (
            /* 已撤回消息的显示保持不变 */
            <div className={`rounded-lg px-3 py-2 bg-gray-100 text-gray-500 italic ${isOwn ? 'ml-auto' : 'mr-auto'}`}>
              <span className="flex items-center">
                <Ban size={14} className="mr-1" />
                This message has been recalled
              </span>
            </div>
          ) : (
            <div 
              className={`rounded-lg px-3 py-2 ${
                isOwn ? 'bg-purple-100 text-purple-800' : 
                isAI ? 'bg-blue-50 text-gray-800' : 'bg-gray-100 text-gray-800'
              } break-words relative transition-all duration-200`}
              onClick={() => {
                if (isOwn && canRecall) {
                  setSelectedMessageId(selectedMessageId === msg.messageId ? null : msg.messageId);
                }
              }}
            >
              {/* 1. 处理用户发送的图片消息 */}
              {isOwn && shouldRenderImage(msg) && renderImageContent(msg)}
              
              {/* 2. AI消息特殊处理：显示用户上传的图片 */}
              {isAI && (msg.chatImages || msg.images) && (
                <div className="mb-2">
                  {/* 使用可选链和空数组作为后备，支持chatImages或images字段 */}
                  {(msg.chatImages || msg.images || []).map((image, idx) => (
                    <img
                      key={idx}
                      src={image.thumbnailUrl}
                      alt={`User uploaded ${idx + 1}`}
                      className="max-w-[200px] rounded-lg cursor-pointer mb-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        openImageModal(image.fileUrl, image.fileName);
                      }}
                      onError={(e) => {
                        console.error('AI referenced image loading failed:', image.thumbnailUrl);
                        e.target.src = '/images/image-placeholder.png';
                      }}
                    />
                  ))}
                </div>
              )}
              
              {/* 3. AI正在思考状态 */}
              {isAI && msg.isTyping ? (
                <div className="flex items-center">
                  <span>{msg.content}</span>
                  <div className="ml-2 flex space-x-1">
                    <div className="w-2 h-2 bg-blue-400 rounded-full animate-bounce" style={{ animationDelay: "0s" }}></div>
                    <div className="w-2 h-2 bg-blue-400 rounded-full animate-bounce" style={{ animationDelay: "0.2s" }}></div>
                    <div className="w-2 h-2 bg-blue-400 rounded-full animate-bounce" style={{ animationDelay: "0.4s" }}></div>
                  </div>
                </div>
              ) : (
                /* 关键修改：使用我们的renderMessageContent函数 */
                !(isOwn && shouldRenderImage(msg) && !msg.content) && renderMessageContent(msg, isOwn)
              )}
              
              {/* 撤回按钮部分保持不变 */}
              {isSelected && canRecall && (
                <button 
                  className="absolute right-[-30px] top-1/2 transform -translate-y-1/2 bg-white rounded-full p-1 shadow-md text-gray-500 hover:text-red-500 transition-colors"
                  onClick={(e) => {
                    e.stopPropagation();
                    recallMessage(msg.messageId);
                  }}
                  title="Recall message"
                >
                  <Undo2 size={16} />
                </button>
              )}
            </div>
          )}
        </div>
        
        {/* 消息状态指示器部分保持不变 */}
        {isOwn && !isRecalled && (
          <div className="absolute bottom-0 -left-4 flex items-center">
            {renderMessageStatus()}
          </div>
        )}
      </div>
    </div>
  );
};

  //   // / 处理消息点击事件
  // const handleMessageClick = (message, event) => {
  //   // 阻止事件冒泡
  //   event.stopPropagation();
  //   if (chatType === 'ai') return;
  //   // 检查是否是自己发送的消息且在3分钟内
  //   const messageTime = new Date(message.timestamp);
  //   const now = new Date();
  //   const diffMinutes = (now - messageTime) / (1000 * 60);
    
  //   // 只有自己发送的文本消息且在3分钟内才显示撤回选项
  //   if (message.sender.toString() === user.id.toString() && 
  //       message.messageType === 'text' && 
  //       diffMinutes <= 3) {
  //     // 设置当前选中的消息ID
  //     setContextMenu({
  //       visible: true,
  //       messageId: message.messageId,
  //       canRecall: true
  //     });
  //   } else {
  //     // 如果不能撤回，就关闭上下文菜单
  //     closeContextMenu();
  //   }
  // };
    


  // 处理长按开始
  const handleTouchStart = (e, message) => {
    if (chatType === 'ai') return;
    // 开始长按计时器
    const timer = setTimeout(() => {
      handleMessageContextMenu(e, message);
    }, 500); // 500ms长按触发
    
    setLongPressTimer(timer);
  };

  // 处理长按结束
  const handleTouchEnd = () => {
    // 清除长按计时器
    if (longPressTimer) {
      clearTimeout(longPressTimer);
      setLongPressTimer(null);
    }
  };

  const renderContextMenu = () => {
    if (!contextMenu.visible) return null;
    if (chatType === 'ai') return null;
    
    return (
      <div 
        className="fixed bg-white shadow-lg rounded-md py-1 z-50 context-menu"
        style={{ top: contextMenu.y, left: contextMenu.x }}
        onClick={(e) => e.stopPropagation()} // 防止点击菜单时关闭
      >
        {contextMenu.canRecall && (
          <button 
            className="w-full text-left px-4 py-2 hover:bg-gray-100 text-red-500 flex items-center"
            onClick={() => recallMessage(contextMenu.messageId)}
          >
            <Ban size={14} className="mr-2" />
            Recall message
          </button>
        )}
      </div>
    );
  };
  
  

  // // 重试发送失败的消息
  // const retryMessage = async (message) => {
  //   try {
  //     // 更新消息状态为"发送中"
  //     setMessages(prev => 
  //       prev.map(msg => 
  //         msg.messageId === message.messageId 
  //           ? { ...msg, status: 'sending' } 
  //           : msg
  //       )
  //     );
      
  //     // 重新发送消息
  //     const result = await chatService.sendMessage({
  //       receiver: message.receiver,
  //       receiverRole: message.receiverRole,
  //       content: message.content,
  //       messageType: message.messageType,
  //       messageId: message.messageId,
  //       // 如果是文件或图片消息，添加相关信息
  //       ...(message.messageType === 'file' && {
  //         fileUrl: message.fileUrl,
  //         fileName: message.fileName,
  //         fileSize: message.fileSize,
  //         fileMimeType: message.fileMimeType
  //       }),
  //       ...(message.messageType === 'image' && {
  //         fileUrl: message.fileUrl,
  //         thumbnailUrl: message.thumbnailUrl
  //       })
  //     });
      
  //     // 更新消息状态为"已发送"
  //     setMessages(prev => 
  //       prev.map(msg => 
  //         msg.messageId === message.messageId 
  //           ? { ...msg, status: 'sent' } 
  //           : msg
  //       )
  //     );
      
  //     console.log('Message resent successfully:', result);
  //   } catch (error) {
  //     console.error('Failed to resend message:', error);
      
  //     // 更新消息状态为"发送失败"
  //     setMessages(prev => 
  //       prev.map(msg => 
  //         msg.messageId === message.messageId 
  //           ? { ...msg, status: 'failed' } 
  //           : msg
  //       )
  //     );
      
  //     // 显示错误提示
  //     setUploadError('Failed to resend message: ' + error.message);
  //   }
  // };

// 渲染消息内容
const renderMessageContent = (message, isOwn) => {
  // 对于被撤回的消息
  if (message.recalled) {
    return <p className="text-gray-400 italic text-sm">此消息已被撤回</p>;
  }
  
  // 对于正在输入的消息
  if (message.isTyping) {
    return (
      <div className="flex">
        <div className="typing-indicator">
          <span></span><span></span><span></span>
        </div>
      </div>
    );
  }
  
  // AI 界面的特殊处理
  if (message.chatType === 'ai') {
    // 处理AI聊天中的文件消息 - 这是关键的特殊处理
    if (message.messageType === 'file') {
      // 判断是否为图片文件
      const isImageFile = 
        (message.files && message.files.some(f => f.type?.startsWith('image/'))) || 
        message.fileMimeType?.startsWith('image/');
      
      if (isImageFile) {
        // 渲染AI界面中的图片内容
        // 获取缩略图URL
        const thumbnailUrl = message.metadata?.thumbnailUrl ||
                            message.thumbnailUrl ||
                            message.fileUrl;
        
        // 获取原图URL
        const originalUrl = message.fileUrl;
        
        return (
          <div
            className="cursor-pointer relative ai-image-container"
            onClick={() => openImageModal(thumbnailUrl, message.fileName, originalUrl)}
            style={{ maxWidth: '250px' }} // AI界面的图片可能需要更大的显示区域
          >
            <img
              src={thumbnailUrl}
              alt={message.fileName || 'AI生成的图片'}
              className="w-full rounded-lg"
              onError={(e) => {
                e.target.src = '/images/image-placeholder.png';
                console.error('AI图片加载失败:', thumbnailUrl);
              }}
            />
          </div>
        );
      } else {
        // 非图片文件，使用AI特定的文件展示方式
        return (
          <div className="ai-file-container bg-gray-50 rounded-lg p-3 max-w-md">
            <div className="flex items-center">
              <div className="text-blue-500 mr-3">
                {getFileIcon(message.fileMimeType)}
              </div>
              <div className="flex-1">
                <div className="font-medium">{message.fileName}</div>
                <div className="text-sm text-gray-500">{formatFileSize(message.fileSize)}</div>
              </div>
              <button
                className="bg-blue-100 text-blue-700 px-3 py-1 rounded hover:bg-blue-200"
                onClick={() => window.open(message.fileUrl, '_blank')}
              >
                查看
              </button>
            </div>
          </div>
        );
      }
    }
  }
  
  // 常规消息类型处理
  switch (message.messageType) {
    case 'file_uploading':
      // 渲染上传中的文件
      return (
        <div className="relative">
          <div className="bg-white rounded-lg border p-3 flex items-center space-x-3">
            {/* 文件图标 - 根据文件类型显示不同图标 */}
            <div className="text-gray-500">
              {getFileIcon(message.fileMimeType)}
            </div>
            
            <div className="flex-1 min-w-0">
              <div className="text-xsm font-medium truncate">{message.fileName}</div>
              <div className="text-xsm text-gray-500">{formatFileSize(message.fileSize)}</div>
              
              {/* 上传进度条 */}
              {message.isUploading ? (
                <div className="mt-2">
                  <div className="h-1.5 w-full bg-gray-200 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-purple-500 rounded-full transition-all duration-300"
                      style={{ width: `${message.uploadProgress}%` }}
                    />
                  </div>
                  <div className="text-xsm text-right mt-0.5 text-gray-500">
                    {Math.round(message.uploadProgress)}%
                  </div>
                </div>
              ) : message.error ? (
                <div className="mt-1 text-xsm text-red-500 flex items-center">
                  <AlertCircle size={12} className="mr-1" />
                  {message.error}
                  <button
                    className="ml-2 text-xsm bg-purple-500 text-white px-2 py-0.5 rounded"
                    onClick={() => retryFileUpload(message)}
                  >
                    重试
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      );
      
    case 'file':
      // 判断是否为图片文件
      const isImageFile = 
        (message.files && message.files.some(f => f.type?.startsWith('image/'))) || 
        message.fileMimeType?.startsWith('image/');
      
      if (isImageFile) {
        // 获取缩略图URL
        const thumbnailUrl = message.metadata?.thumbnailUrl ||
                            message.thumbnailUrl ||
                            message.fileUrl;
        
        // 获取原图URL
        const originalUrl = message.fileUrl;
        
        // 判断当前显示的是否为原图
        const isShowingOriginal = !message.thumbnailUrl && !message.metadata?.thumbnailUrl;
        
        return (
          <div
            className="cursor-pointer relative"
            onClick={() => openImageModal(thumbnailUrl, message.fileName, originalUrl, isShowingOriginal)}
            style={{ width: '150px', height: '100px' }}
          >
            {/* 图片加载状态 */}
            {message.isLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-100 rounded-lg">
                <Loader className="text-purple-500 animate-spin" size={16} />
              </div>
            )}
            
            {/* 图片 */}
            <img
              src={thumbnailUrl}
              alt={message.fileName || '图片'}
              className="w-full h-full object-cover rounded-lg"
              style={{
                opacity: message.isLoading ? 0 : 1,
                transition: 'opacity 0.3s ease'
              }}
              onLoad={() => {
                // 图片加载完成后更新消息状态
                const updatedMessages = messages.map(m =>
                  m.messageId === message.messageId ? { ...m, isLoading: false } : m
                );
                setMessages(updatedMessages);
              }}
              onError={(e) => {
                // 如果加载失败，显示占位图
                e.target.src = '/images/image-placeholder.png';
                console.error('图片加载失败:', thumbnailUrl);
                
                // 更新消息状态
                const updatedMessages = messages.map(m =>
                  m.messageId === message.messageId ? { ...m, isLoading: false } : m
                );
                setMessages(updatedMessages);
              }}
            />
          </div>
        );
      } else {
        // 非图片文件，显示文件信息
        // 获取文件图标
        const fileIcon = getFileIcon(message.fileMimeType);
        
        // 创建下载函数
        // const downloadFile = (url, filename) => {
        //   // 显示下载中状态
        //   const downloadingMessage = `正在下载 ${filename}...`;
        //   console.log(downloadingMessage);
          
        //   // 使用fetch API获取文件
        //   fetch(url)
        //     .then(response => {
        //       if (!response.ok) {
        //         throw new Error(`下载失败: ${response.status} ${response.statusText}`);
        //       }
        //       return response.blob();
        //     })
        //     .then(blob => {
        //       // 创建一个临时URL
        //       const url = window.URL.createObjectURL(blob);
              
        //       // 创建一个隐藏的a标签
        //       const link = document.createElement('a');
        //       link.href = url;
        //       link.download = filename;
              
        //       // 添加到DOM并触发点击
        //       document.body.appendChild(link);
        //       link.click();
              
        //       // 清理
        //       window.URL.revokeObjectURL(url);
        //       document.body.removeChild(link);
              
        //       console.log(`${filename} 下载完成`);
        //     })
        //     .catch(error => {
        //       console.error('下载文件失败:', error);
        //       alert(`下载失败: ${error.message}`);
        //     });
        // };

        const downloadFile = (url, filename) => {
          // 创建一个临时的a标签
          const a = document.createElement('a');
          a.href = url;
          a.download = filename || '下载文件'; // 如果没有提供文件名，使用默认名称
          a.target = '_blank'; // 在新标签页中打开
          
          // 添加到DOM并触发点击
          document.body.appendChild(a);
          a.click();
          
          // 清理DOM
          setTimeout(() => {
            document.body.removeChild(a);
          }, 100);
        };
        
        return (
          <div className="flex items-center space-x-2 p-2 bg-white rounded-lg border">
            <div className="text-gray-500">
              {fileIcon}
            </div>
            <div className="flex-1 truncate">
              <div className="text-xsm font-medium truncate">{message.fileName}</div>
              <div className="text-xsm text-gray-500">{formatFileSize(message.fileSize)}</div>
            </div>
            <button
              className="text-purple-500 hover:text-purple-700 p-1.5 rounded-full hover:bg-gray-100"
              title="下载文件"
              onClick={() => downloadFile(message.fileUrl, message.fileName)}
            >
              <Download size={16} />
            </button>
          </div>
        );
      }
      
    case 'image':
      // 获取缩略图URL
      const thumbnailUrl = message.metadata?.thumbnailUrl ||
                          message.thumbnailUrl ||
                          message.fileUrl;
      
      // 获取原图URL
      const originalUrl = message.fileUrl;
      
      // 判断当前显示的是否为原图
      const isShowingOriginal = !message.thumbnailUrl && !message.metadata?.thumbnailUrl;
      
      return (
        <div
          className="cursor-pointer relative"
          onClick={() => openImageModal(thumbnailUrl, message.fileName, originalUrl, isShowingOriginal)}
          style={{ width: '150px', height: '100px' }}
        >
          {/* 图片加载状态 */}
          {message.isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-100 rounded-lg">
              <Loader className="text-purple-500 animate-spin" size={16} />
            </div>
          )}
          
          {/* 图片 */}
          <img
            src={thumbnailUrl}
            alt={message.fileName || '图片'}
            className="w-full h-full object-cover rounded-lg"
            style={{
              opacity: message.isLoading ? 0 : 1,
              transition: 'opacity 0.3s ease'
            }}
            onLoad={() => {
              // 图片加载完成后更新消息状态
              const updatedMessages = messages.map(m =>
                m.messageId === message.messageId ? { ...m, isLoading: false } : m
              );
              setMessages(updatedMessages);
            }}
            onError={(e) => {
              // 如果加载失败，显示占位图
              e.target.src = '/images/image-placeholder.png';
              console.error('图片加载失败:', thumbnailUrl);
              
              // 更新消息状态
              const updatedMessages = messages.map(m =>
                m.messageId === message.messageId ? { ...m, isLoading: false } : m
              );
              setMessages(updatedMessages);
            }}
          />
        </div>
      );

    default:
      // 文本消息
      return <div className="whitespace-pre-wrap break-words text-sm">{message.content || ""}</div>;
  }
};

// 监听页面可见性变化
useEffect(() => {
  const handleVisibilityChange = () => {
    const isVisible = document.visibilityState === 'visible';
    setIsPageVisible(isVisible);
    
    // 如果页面变为可见且有选中的管理员，检查是否需要标记为已读
    if (isVisible && adminInfo && chatOpen && !chatMinimized) {
      const conversationId = `admin_${adminInfo.adminId}_teacher_${user.id}`;
      markConversationReadIfVisible(conversationId);
    }
  };
  
  // 添加可见性变化事件监听
  document.addEventListener('visibilitychange', handleVisibilityChange);
  
  return () => {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  };
}, [adminInfo, chatOpen, chatMinimized, user?.id]);

// 标记消息为已读的效果（当页面可见且聊天窗口打开时）
useEffect(() => {
  // 确保有选中的管理员，且有消息，且组件已挂载，且页面可见，且聊天窗口打开
  if (adminInfo && messages.length > 0 && messageContainerRef.current && isPageVisible && chatOpen && !chatMinimized) {
    // 构建会话ID
    const conversationId = `admin_${adminInfo.adminId}_teacher_${user.id}`;
    
    // 检查是否有未读的接收消息（对方发送给我的）
    const hasUnreadReceivedMessages = messages.some(
      msg => msg.sender !== user.id && (!msg.status || msg.status === 'sent' || msg.status === 'delivered')
    );
    
    // 只有存在未读的接收消息时才标记为已读
    if (hasUnreadReceivedMessages) {
      // 延迟一小段时间确保消息已显示
      const timer = setTimeout(() => {
        // console.log('存在未读的接收消息，且页面可见，标记为已读');
        chatService.markConversationAsRead(conversationId)
          .catch(error => {
            console.error('Mark conversation as read failed:', error);
          });
      }, 500);
      
      return () => clearTimeout(timer);
    }
  }
}, [adminInfo, messages, user?.id, isPageVisible, chatOpen, chatMinimized]);

// 添加标记会话为已读的辅助函数
const markConversationReadIfVisible = (conversationId) => {
  // 检查是否有未读的接收消息
  const hasUnreadReceivedMessages = messages.some(
    msg => msg.sender !== user.id && (!msg.status || msg.status === 'sent' || msg.status === 'delivered')
  );
  
  // 只有当页面可见、存在未读消息时才标记为已读
  if (isPageVisible && hasUnreadReceivedMessages && chatOpen && !chatMinimized) {
    // console.log('页面可见且有未读消息，标记为已读');
    chatService.markConversationAsRead(conversationId).catch(error => {
      console.error('Mark conversation as read failed:', error);
    });
  }
};

// 监听会话消息已读状态更新
useEffect(() => {
  const handleConversationMessagesRead = (data) => {
    const { conversationId, timestamp } = data;

      // 如果是当前管理员的会话被标记为已读，重置未读计数
    if (data.conversationId.includes(`admin_${adminInfo?.adminId}_teacher_${user.id}`)) {
      setUnreadMessages(0);
    }
    
    // 只处理当前选中的会话
    if (adminInfo && `admin_${adminInfo.adminId}_teacher_${user.id}` === conversationId) {
      // 更新所有自己发送的消息状态为已读
      setMessages(prevMessages => 
        prevMessages.map(msg => 
          msg.sender === user.id && (!msg.status || msg.status === 'sent' || msg.status === 'delivered')
            ? { ...msg, status: 'read', readAt: timestamp } 
            : msg
        )
      );
    }
  };

  
  
  // 添加事件监听
  chatService.on('conversation_messages_read', handleConversationMessagesRead);
  chatService.on('conversation_read', handleConversationMessagesRead);
  chatService.on('conversation_read_receipt', handleConversationMessagesRead);
  
  // 清理事件监听
  return () => {
    chatService.removeListener('conversation_messages_read', handleConversationMessagesRead);
    chatService.removeListener('conversation_read', handleConversationMessagesRead);
    chatService.removeListener('conversation_read_receipt', handleConversationMessagesRead);
  };
}, [adminInfo, user?.id]);

// 渲染聊天选项卡
const renderChatTabs = () => {
  return (
    <div className="chat-tabs">
      <div 
        className={`chat-tab ${chatType === 'admin' ? 'active' : ''}`}
        onClick={() => {
          setChatType('admin');
          // 切换到管理员聊天时，重置未读消息计数
          setUnreadMessages(0);
        }}
      >
        管理员
        {/* 添加未读消息指示器 */}
        {chatType !== 'admin' && unreadMessages > 0 && (
          <span className="unread-badge">{unreadMessages}</span>
        )}
      </div>
      <div 
        className={`chat-tab ${chatType === 'ai' ? 'active' : ''}`}
        onClick={() => {
          setChatType('ai');
          setShowNewChatButton(true);
        }}
      >
        <img 
          src={ai} 
          alt="AI Assistant" 
          className="ai-avatar"
        />
        AI Assistant
      </div>
    </div>
  );
};

  // 添加新对话按钮
  // const renderNewChatButton = () => {
  //   if (!showNewChatButton || chatType !== 'ai') return null;
    
  //   return (
  //     <button 
  //       className="new-chat-button"
  //       onClick={handleNewChat}
  //       title="清除当前会话"
  //     >
  //       <Eraser size={20} />
  //     </button>
  //   );
  // };

// 处理新对话
  const handleNewChat = async () => {
    try {
      await chatService.newChat();

      const aiConversationId = `chat_history_teacher_${user.id}_ai-assistant`;
      await chatService.newChat(aiConversationId);
      // 清空当前消息列表
      setMessages([]);
      // 添加默认欢迎消息
      const welcomeMessage = {
        messageId: `ai_welcome_${Date.now()}`,
        sender: 'ai-assistant',
        content: "Hi! I'm your AI assistant. How can I help you today?",
        timestamp: new Date().toISOString(),
        messageType: 'text'
      };
      setMessages([welcomeMessage]);
    } catch (error) {
      console.error('Start a new AI conversation failed:', error);
    }
};

return (
  <div className="fixed bottom-4 right-4 z-50">
    {/* 聊天图标按钮 */}
    {!chatOpen && (
      <button 
        onClick={openChat}
        className="bg-purple-500 hover:bg-purple-600 text-white rounded-full w-14 h-14 flex items-center justify-center shadow-lg transition-all duration-300 hover:scale-110 relative"
      >
        <MessageSquare size={24} />
        {unreadMessages > 0 && (
          <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xsm rounded-full h-5 w-5 flex items-center justify-center">
            {unreadMessages}
          </span>
        )}
      </button>
    )}

    {/* 上下文菜单 - 确保它在DOM中的位置正确 */}
    {renderContextMenu()}

    {/* 聊天窗口 */}
    {chatOpen && (
      <div className={`bg-white rounded-lg shadow-xl overflow-hidden flex flex-col transition-all duration-300 ${
        chatMinimized ? 'w-72 h-12' : 'w-112'
      }`} style={{
        height: chatMinimized ? '48px' : 'calc(100vh - 80px)',
        // maxHeight: '500px',
        position: 'absolute',
        bottom: 0,
        right: 0
      }}>

        {/* 聊天窗口标题栏 */}
        <div className="chat-header">
        <div className="chat-tabs-container">
            <button 
              className={`chat-tab-button ${chatType === 'admin' ? 'active' : ''}`}
              onClick={() => {

                setNewMessage('');
                setPastedImage(null);
                setPastedFiles([]);
                setChatType('admin');

                if (adminInfo) {
                  const conversationId = `admin_${adminInfo.adminId}_teacher_${user.id}`;
                  chatService.getMessageHistory({ conversationId })
                    .then(({ messages }) => {
                      if (Array.isArray(messages)) {
                        const sortedMessages = [...messages].sort((a, b) => 
                          new Date(a.timestamp) - new Date(b.timestamp)
                        );
                        const processedMessages = preprocessMessages(sortedMessages);
                        setMessages(sortedMessages);
                        // 重置未读消息计数，因为用户已经点击查看了
                        setUnreadMessages(0);
                      }
                    })
                    .catch(error => {
                      console.error('Get admin messages failed:', error);
                    });
                }
              }}
            >
              <div className="flex items-center relative">
                {activeAdmins.length > 0 && (
                  <div className="chat-avatar">
                    {renderAdminAvatar(activeAdmins[0])}
                  </div>
                )}
                <span className="text-sm px-2 pt-1">Ms.{adminInfo?.adminName}</span>
                  {/* 添加未读消息徽章 */}
                  {/* {console.log('未读消息状态:', unreadMessages, 'chatType:', chatType)} */}
                  {unreadMessages > 0 && chatType !== 'admin' && (
                    <div className="absolute -top-1 -right-6 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                      {unreadMessages > 99 ? '99+' : unreadMessages}
                    </div>
                  )}
                  </div>
            </button>
            
            <button 
                className={`chat-tab-button ${chatType === 'ai' ? 'active' : ''}`}
                onClick={() => {
                  setChatType('ai');
                  setShowNewChatButton(true);
                  
                  // 使用统一的会话ID格式
                  const aiConversationId = `chat_history_teacher_${user.id}_ai-assistant`;
                  chatService.getMessageHistory({ conversationId: aiConversationId })
                    .then(({ messages }) => {
                      if (Array.isArray(messages) && messages.length > 0) {
                        const sortedMessages = [...messages].sort((a, b) => 
                          new Date(a.timestamp) - new Date(b.timestamp)
                        );
                        setMessages(sortedMessages);
                      } else {
                        const welcomeMessage = {
                          messageId: `ai_welcome_${Date.now()}`,
                          sender: 'ai-assistant',
                          content: "Hi! I'm your AI assistant. How can I help you today?",
                          timestamp: new Date().toISOString(),
                          messageType: 'text'
                        };
                        setMessages([welcomeMessage]);
                      }
                    })
                    .catch(error => {
                      console.error('获取AI聊天历史失败:', error);
                      const welcomeMessage = {
                        messageId: `ai_welcome_${Date.now()}`,
                        sender: 'ai-assistant',
                        content: "Hi! I'm your AI assistant. How can I help you today?",
                        timestamp: new Date().toISOString(),
                        messageType: 'text'
                      };
                      setMessages([welcomeMessage]);
                    });
                }}
>
              <div className="flex items-center">
                <div className="chat-avatar">
                  <img 
                    src={ai} 
                    alt="AI" 
                    className="w-full h-full object-cover"
                  />
                </div>
                <span className="text-sm">AI Assistant</span>
              </div>
            </button>
          </div>
          
          {/* 右侧控制按钮 */}
          <div className="flex absolute right-2 top-1/2 transform -translate-y-1/2">
            <button 
              onClick={toggleMinimize} 
              className="p-1 text-white hover:bg-purple-600 rounded transition-colors"
            >
              {chatMinimized ? <Maximize size={16} /> : <Minimize size={16} />}
            </button>
            <button 
              onClick={closeChat} 
              className="p-1 text-white hover:bg-purple-600 rounded ml-1 transition-colors"
            >
              <XIcon size={16} />
            </button>
          </div>
        </div>
          
        
        {!chatMinimized && (
          <div 
            className="flex flex-col h-[calc(100%-30px)] relative"
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {/* 拖放覆盖层 - 仅在拖放时显示 */}
            {isDragging && (
              <div className="absolute inset-0 bg-purple-100 bg-opacity-70 flex items-center justify-center z-10 border-2 border-dashed border-purple-500 rounded-lg">
                <div className="text-center p-4 bg-white rounded-lg shadow-md">
                  <Paperclip size={32} className="mx-auto mb-2 text-purple-500" />
                  <p className="text-purple-700 font-medium">Drop files here or click to upload</p>
                  <p className="text-gray-500 text-sm mt-1">Support images, documents, and compressed files</p>
                </div>
              </div>
            )}
            
            {/* 消息列表区域 */}
            <div 
              ref={messageContainerRef}
              className="flex-1 overflow-y-auto p-4 scrollbar-hide"
              style={{ 
                scrollbarWidth: 'none', /* Firefox */
                msOverflowStyle: 'none',  /* IE and Edge */
              }}
              onClick={closeContextMenu} // 点击消息区域关闭上下文菜单
            >
              {/* 使用普通style标签代替jsx */}
              <style>{`
                div::-webkit-scrollbar {
                  display: none; /* Chrome, Safari, Opera */
                }
                
                /* 添加这个选择器，为所有消息项之间添加垂直间距 */
                .message-item + .message-item {
                  margin-top: 16px;
                }
                
                /* 大幅增加第一个消息的顶部边距 */
                .message-item:first-child {
                  margin-top: 24px; /* 从8px增加到24px */
                }
                
                .message-item:last-child {
                  margin-bottom: 16px; /* 也增加一下底部边距 */
                }
              `}</style>

              {/* 加载更多按钮 */}
              {renderLoadMoreButton()}

              {chatType === 'ai' && (
                <div className="fixed-new-chat-button">
                  <button 
                    onClick={handleNewChat}
                    title="New Conversation"
                  >
                    <Paintbrush size={20} />
                  </button>
                </div>
              )}

              {/* 添加新聊天按钮 - 仅在 AI 模式下显示 */}
              {/* {chatType === 'ai' && (
                <button 
                      // Start of Selection
                  className="fixed bottom-96 right-12 bg-purple-500 hover:bg-purple-600 text-white rounded-full p-3 shadow-lg z-10 transition-all hover:scale-105"
                  onClick={handleNewChat}
                  title="开始新对话"
                >
                  <Paintbrush size={20} />
                </button>
              )} */}
              
              {messages.length > 0 ? (
                messages.map((msg, index) => renderMessage(msg, index))
              ) : (
                <div className="flex items-center justify-center h-full text-gray-500">
                  No message records yet
                </div>
              )}
              
              {/* 修改这个元素的样式，防止影响其他元素 */}
              {/* <div 
                ref={messagesEndRef} 
                style={{ 
                  height: '1px', 
                  width: '1px',
                  margin: 0,
                  padding: 0,
                  visibility: 'hidden' // 使其不可见但仍占位
                }}
              /> */}
            </div>

            <ImageViewer 
              isOpen={imageModal.isOpen}
              onClose={closeImageModal}
              imageUrl={imageModal.imageUrl}
              imageName={imageModal.imageName}
              originalUrl={imageModal.originalUrl}
              isOriginal={imageModal.isOriginal}
              onLoadOriginal={loadOriginalImage}
            />
            
            {/* 消息输入区域 */}
            <div className="border-t bg-gray-50 p-4">
              {/* 错误提示 */}
              {uploadError && (
                <div className="mb-2 text-xsm text-red-500 flex items-center">
                  <AlertCircle size={12} className="mr-1" />
                  {uploadError}
                  {uploadError.includes('Upload failed') && failedUploadFile && (
                    <button 
                      className="ml-2 text-xsm bg-purple-500 text-white px-2 py-0.5 rounded"
                      onClick={() => handleFileUpload(failedUploadFile)}
                    >
                      Retry
                    </button>
                  )}
                </div>
              )}
              
              <div className="flex items-start">
                {/* 文件上传按钮 */}
                <div className="mr-2">
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                    className="hidden"
                    accept={ALLOWED_FILE_TYPES.join(',')}
                  />
                  <button
                    onClick={() => fileInputRef.current?.click()}
                    className="p-2 rounded-full hover:bg-gray-200 transition-colors"
                    title="Upload files or images"
                  >
                    <Paperclip size={20} className="text-gray-500" />
                  </button>
                </div>
                
                {/* 消息输入框 */}
                <div className="flex-1 relative">
                  <textarea
                    ref={textareaRef}
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onInput={(e) => {
                      // 自动调整高度
                      e.target.style.height = 'auto';
                      const newHeight = Math.min(80, e.target.scrollHeight);
                      e.target.style.height = newHeight + 'px';
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        sendMessage();
                      }
                    }}
                    placeholder={pastedImage ? "" : "Enter message..."}
                    className="w-full py-2 pl-3 pr-10 border rounded-sm text-sm focus:outline-none focus:ring-2 focus:ring-purple-500 resize-none overflow-hidden"
                    style={{ minHeight: '40px', maxHeight: '80px' }}
                  />
                  
                  {/* 表情选择器嵌入到输入框内 */}
                  <div className="absolute right-2 top-2">
                    <EmojiPicker 
                      onEmojiSelect={handleEmojiSelect}
                      buttonRef={emojiButtonRef}
                    />
                  </div>
                  
                  {/* 粘贴图片预览 */}
                  {pastedFiles.length > 0 && (
                    <div className="absolute right-10 top-2 bg-gray-100 rounded-md p-1 flex items-center">
                      <div className="w-5 h-5 mr-1">
                        <File size={16} className="text-purple-500" />
                      </div>
                      <span className="text-xsm text-gray-600 truncate max-w-[100px]">
                        {pastedFiles[0].name || "Pasted File"}
                      </span>
                      <button 
                        className="ml-1 text-gray-500 hover:text-red-500"
                        onClick={() => setPastedFiles([])}
                        title="Remove File"
                      >
                        <XIcon size={14} />
                      </button>
                    </div>
                  )}
                </div>
                
                {/* 发送按钮 */}
                <button
                  onClick={sendMessage}
                  disabled={!newMessage.trim() && !pastedFiles.length}
                  className={`ml-2 p-2 rounded-full ${
                    !newMessage.trim() && !pastedFiles.length
                      ? 'text-gray-400 cursor-not-allowed'
                      : 'text-purple-500 hover:bg-purple-100'
                  } transition-colors`}
                  title="Send message"
                >
                  <Send size={20} />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    )}
  </div>
);
}

export default ChatWidget;